/**
 *  Util.js
 *
 *  @project      shareshima
 *  @since        2015/07/14
 *  @version      0.0.1
 *  @author       K.T.T (Kodama.Tsuji.Tamura)
 */
window.primaryColor = 0;

(function(win, doc, App){

"use strict";

/**
 * お役立ち機能を提供する
 *
 * @return  {object} self
 */
App.Services.Util = function () {
    var self = {};
        
    /** ------------------------------
     *  Initialize
     ---------------------------------*/

    function init () {
       App.Behaviors.Hamburger('.js-hamburger');
    }

    /** ------------------------------
     *  Private
     ---------------------------------*/

    /** ------------------------------
     *  Public
     ---------------------------------*/
     /*
     * splitText
     *
     * @function 文字列を分割して返す
     * @public
     * @see https://qiita.com/kazunorisasaki/items/453e3fdf5692be9be872
     * @param {string} text
     * @param {integer} index 最初に区切る位置
     * @return {array} 前半後半に分けた文字列が入る
     */
    self.splitText = function(text, length) {
        var str = text.trim(),
            strAry = [];

        if (!str || !length || length < 1) {
            return [];
        }
        var regexPattern = new RegExp(
            '(?:[\uD800-\uDBFF][\uDC00-\uDFFF]|[^\uD800-\uDFFF]){1,' + length + '}',
            'g'
        );

        var result = str.match(regexPattern) || [];
        // 前半の文字列セット
        strAry[0] = result[0];
        // 後半の文字列セット
        result.shift();
        strAry[1] = result.join();

        return strAry;
    }

     /*
     * isBrightColor
     *
     * @function 明るい色か判断する
     * @public
     * @see http://qiita.com/kozo002/items/97b3af1388ee4e04b876
     * @param {array} rgb
     * @param {integer} luminThreshold 明るい色か判断する閾値
     * @return {boolean} trueなら明るい色
     */
    self.isBrightColor = function(rgb, luminThreshold) {
        var r = 0.298912,
            g = 0.586611,
            b = 0.114478,
            luminThreshold = luminThreshold || 170,
            result = Math.floor(r * rgb[0] + g * rgb[1] + b * rgb[2]) > luminThreshold;
        return result
    }

    /**
     * template
     *
     * @function templateからDOM化
     * @param   {string} selector
     * @param   {object} args
     * @return  {string} $el
     */
    self.template = function (selector, args) {
        var tmpl = $(selector).html(),
            el = _.template(tmpl, args);
    
        return el;
    };

    /**
     * preventDoubleSubmit
     *
     * @function フォームの二重送信を防止
     * @param  {mixed} selector
     * @return void
     */
    self.preventDoubleSubmit = function (selector) {
        selector = selector || "form";
        var $form = typeof selector === "string" ? $(selector) : selector;
        $form.submit(function() {
            $(this).submit(function () {
                return false;
            });
        });
    };

    /**
     * generateDate
     * 
     * @function ハイフン区切りの文字列をスラッシュに置き換えDate化
     * @param  {string} str_date
     * @return {date} d
     */
    self.generateDate = function (str_date) {
        var d = typeof str_date === "string" ? new Date(str_date.replace(/-/g, "/")) : new Date(str_date);
        return d;
    };

    /**
     * getMoneyFormat
     *
     * @function 引数の数値を通貨形式の文字列で返す
     * @param  {integer} num
     * @return {string}
     */
    self.getMoneyFormat = function (num) {
        var partNum = 3,
            mark = ",",
            newNum = num + "",
            lengNum = newNum.length,
            ltNum, rtNum;
        for(var i = lengNum; i > 0; i--) {
            if(i % partNum == 0 && i != lengNum) {
                ltNum = newNum.slice(0, -i);
                rtNum = newNum.slice(-i);
                newNum = ltNum + mark + rtNum;
            }
        }
        return "￥" + newNum;
    };

    /**
     * getDateStr
     *
     * @function dateから文字列形式の日付を返す
     * @param  {Date}   d
     * @param  {string} sep  optional
     * @return {string} result
     */
    self.getDateStr = function (d, sep) {
        sep = sep || "/";
        var y = d.getFullYear(),
            m = d.getMonth() + 1,
            d = d.getDate(),
            result;
        if (m < 10) {
            m = '0' + m;
        }
        if (d < 10) {
            d = '0' + d;
        }
        result = y + sep + m + sep + d;
        return result;
    };

    /**
     * addFigure
     *
     * @function 三桁カンマ区切りの通貨フォーマットの文字列を返す
     * @param {integer} exp
     * @return void
     */
    self.addFigure = function (exp) {
        var num = new String(exp).replace(/,/g, "");
        while(num != (num = num.replace(/^(-?\d+)(\d{3})/, "$1,$2")));
        return num;
    };

    /**
     *  generateTemplate
     *
     *  @function type/templateな文字列からDOMを生成して返す
     *  @param    {string}    selector type/templateなセレクタ
     *  @return   {jqury dom} $dom
     */
    self.generateTemplate = function (selector) {
        var $dom = $($(selector).html().replace(/\n/g, ""));
        return $dom;
    };

    /**
     *  isIE
     * 
     *  @function jQuery 1.9以降のため$.borowserが利用できないため、IE判定を実装
     *  @return   {boolean} result
     */
    self.isIE = function () {
        var result = /*@cc_on!@*/false; // IEの場合true
        return result;
    };

    /**
     *  camelize
     * 
     *  @function (スネークケースな)textを大文字キャメルケースにして返す
     *  @param    {string} text
     *  @return   {string} result
     */
    self.camelize = function (text) {
        text = text || "";
        return text.replace(/(^.)|_([a-z])/ig, function (all, letter) {
            if (letter) {
                return letter.toUpperCase();
            } else {
                return all.replace("_", "").toUpperCase();
            }
        });
    };

    /**
     *  isMobileTablet
     *
     *  @function スマホかタブレットか判定
     * @public
     * @see https://w3g.jp/blog/js_browser_sniffing2015
     * @return {object}
     */
    self.isMobileTablet = function (ua) {
      var ua = ua.toLowerCase() || window.navigator.userAgent.toLowerCase();
      return {
        Tablet:(ua.indexOf("windows") != -1 && ua.indexOf("touch") != -1 && ua.indexOf("tablet pc") == -1)
          || ua.indexOf("ipad") != -1
          || (ua.indexOf("android") != -1 && ua.indexOf("mobile") == -1)
          || (ua.indexOf("firefox") != -1 && ua.indexOf("tablet") != -1)
          || ua.indexOf("kindle") != -1
          || ua.indexOf("silk") != -1
          || ua.indexOf("playbook") != -1,
        Mobile:(ua.indexOf("windows") != -1 && ua.indexOf("phone") != -1)
          || ua.indexOf("iphone") != -1
          || ua.indexOf("ipod") != -1
          || (ua.indexOf("android") != -1 && ua.indexOf("mobile") != -1)
          || (ua.indexOf("firefox") != -1 && ua.indexOf("mobile") != -1)
          || ua.indexOf("blackberry") != -1
      }
    };

    /**
     *  getuserAgent
     *
     *  @function　ユーザーエージェント判定
     *  @return {object} uaresparam
     */
    self.getuserAgent = function (ua) {
        var self = this,
            ua = (ua) ? ua.toLowerCase() : window.navigator.userAgent.toLowerCase(),
            ver = window.navigator.appVersion.toLowerCase(),
            SPTB = self.isMobileTablet(ua),
            version,
            uaresparam = {
            'Win': false,
            'Win81': false,
            'Win8': false,
            'iOS': false,
            'iPhone': false,
            'iPad': false,
            'Android': false,
            'AndroidUnder42': false,
            'SP_TB': false,
            'IE' : false,
            'IE6' : false,
            'IE7' : false,
            'IE8' : false,
            'IE9' : false,
            'IE10' : false,
            'IE11' : false,
            'Edge' : false,
            'Firefox' : false,
            'Chrome' : false,
            'Safari' : false,
            'Opera' : false,
            'iOS_Chrome' : false,
            'AndroidBrowser' : false
        };

        if (ua.indexOf('edge') != -1){
            uaresparam.IE = true;
            uaresparam.Edge = true;
        }else if (ua.indexOf('msie') != -1) {
            uaresparam.IE = true;
            if (ver.indexOf('msie 6.') != -1) {
                uaresparam.IE6 = true;
            } else if (ver.indexOf('msie 7.') != -1) {
                uaresparam.IE7 = true;
            } else if (ver.indexOf('msie 8.') != -1) {
                uaresparam.IE8 = true;
            } else if (ver.indexOf('msie 9.') != -1) {
                uaresparam.IE9 = true;
            } else if (ver.indexOf('msie 10.') != -1) {
                uaresparam.IE10 = true;
            }
        }else if (ua.indexOf('trident/7') != -1){
                uaresparam.IE = true;
                uaresparam.IE11 = true;
        } else if (ua.indexOf('iphone') != -1) {
            uaresparam.SP_TB = true;
            uaresparam.iOS = true;
            uaresparam.iPhone = true;
        } else if (ua.indexOf('ipad') != -1) {
            uaresparam.SP_TB = true;
            uaresparam.iOS = true;
            uaresparam.iPad = true;
        } else if (ua.indexOf('android') != -1) {
            version = parseFloat(ua.slice(ua.indexOf("android")+8));
            if(version <= 4.2){
              uaresparam.AndroidUnder42 = true;
            }
            uaresparam.SP_TB = true;
            uaresparam.Android = true;
        } else if (ua.indexOf('firefox') != -1) {
            uaresparam.Firefox = true;
        } else if (ua.indexOf('chrome') != -1){
            uaresparam.Chrome = true;
        } else if (ua.indexOf('safari') != -1){
            uaresparam.Safari = true;
        } else if (ua.indexOf('opera') != -1){
            uaresparam.Opera = true;
        }

        if (ua.indexOf('windows') != -1){
            uaresparam.Win = true;
        }

        if (ua.indexOf('crios') != -1){
            uaresparam.iOS_Chrome = true;
        }

        if(ua.match(/win(dows )?nt 6\.3/)){
            uaresparam.Win81 = true;
        }else if(ua.match(/win(dows )?nt 6\.2/)){
            uaresparam.Win8 = true;
        }
        /*
        * Android標準ブラウザ
        * @see https://qiita.com/fujimu/items/a0501acc7d9b93cf6d3b
        */
        if ((/android/.test(ua) && /linux; u;/.test(ua) && !/chrome/.test(ua)) ||
            (/android/.test(ua) && /chrome/.test(ua) && /version/.test(ua)) ||
            (/android/.test(ua) && /chrome/.test(ua) && /samsungbrowser/.test(ua))) {
            uaresparam.AndroidBrowser = true;
        }

        uaresparam.IS_SP = SPTB.Mobile;
        uaresparam.IS_TB = SPTB.Tablet;

        return uaresparam;
    };

    /**
     * ajax
     *
     * @function Ajax通信
     * @param {string} type
     * @param {string} url
     * @param {object} params
     * @param {string} datatype
     * @return {object}
     */
    self.ajax = function(type, url, params, datatype) {
        var defer = $.Deferred();
        $.ajax({
            type : type,
            cache : false,
            timeout: 30000,
            url : url,
            data : params,
            dataType : datatype,
            success: defer.resolve,
            error: defer.reject
        });
        return defer.promise();
    };

    /**
     * checkLogin
     *
     * @function ログインチェック
     * @param {string} selector
     * @return {boolean}
     */
    self.checkLogin = function (selector) {
        var is_login= $(selector).data('login-status');
        return (is_login) ? true : false;
    };

    /**
     * checkRequired
     *
     * @function 未入力チェック
     * @param {string} node 入力項目要素
     * @return {boolean}
     */
    self.checkRequired = function (nodes) {
        var value = _($(nodes)).map(function(node) {
            return $(node).val();
        }).join('');
        return !!value;
    };

    /**
     * pickDate
     *
     * @function カレンダーピッカー実装
     * @requires bootstrap-datepicker.js
     * @requires bootstrap-datepicker.ja.js
     * @param {string} selector
     * @return {void}
     */
    self.pickDate = function(selector) {
        var $el = $(selector);
        // カレンダー設定
        $el.datepicker({
            format: 'yyyy/mm/dd',
            language: 'ja',
            autoclose: true,
            clearBtn: true,
            startDate: Date()
        });
    };

    /**
     *  onYouTubeApiLoad
     *
     *  @function　youtube api load
     *  @public
     *  @return {void}
     */
    self.loadOnYouTubeApi = function () {
         // Youtube API
        var tag = document.createElement('script');
        tag.src = "https://www.youtube.com/iframe_api";
        var firstScriptTag = document.getElementsByTagName('script')[0];
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
    };

    /**
     * isHaveUrl
     *
     * @function URLが含まれているかチェック
     * @public
     * @param {string} str
     * @return {boolean}
     */
    self.isHaveUrl = function (str) {
        return str.match(/(https?:\/\/[-_.!~*\'()a-zA-Z0-9;\/?:@&=+$,%#]+)/)
    }

    /**
     * checkFileSize
     *
     * @function ファイルサイズチェック
     * @public
     * @param {string} file
     * @param {integer} size
     * @return {boolean}
     */
    self.checkFileSize = function (file, size) {
        var checkSize = size || 15;

        return file.size <= 1024 * 1024 * checkSize
    }

    /**
     * hasAttr
     *
     * @function 属性存在チェック
     * @public
     * @param {Object} $obj jquery object
     * @param {string} attrName
     * @return {boolean}
     */
    self.hasAttr = function ($obj, attrName) {
        var attr = $obj.attr(attrName);

        if (typeof attr !== typeof undefined && attr !== false && attr != 'false') {
            return true;
        } else {
            return false;
        }
    }

    /**
     * zeroPadding
     *
     * @function ゼロ埋め
     * @public
     * @see https://qiita.com/_shimizu/items/2cb49daf2eb8ffb30690
     * @param {integer} number
     * @param {integer} length
     * @return {boolean}
     */
    self.zeroPadding = function (number, length) {
        return (Array(length).join('0') + number).slice(-length);
    }

    /**
     * diffMinutes
     *
     * @function ２つの時間の差を分で返す
     * @public
     * @param {time} startTime hh:mm
     * @param {time} endTime hh:mm
     * @return integer or boolean
     */
    self.diffMinutes = function (startTime, endTime) {
      var start = moment(startTime, "HH:mm"),
          end = moment(endTime, "HH:mm"),
          minutes = end.diff(start, 'minutes');

      return minutes;
    }

    // ---------- init and export ----------
    init();
    return self;
};

}(this, this.document, this.App));
