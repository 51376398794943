/**
 *  ModalWordOrderReplyMessage.js
 *
 *  @project      nagano
 *  @since        2019/12/18
 *  @version      0.0.1
 *  @author       Ishibashi
 */

(function(win, doc, App){

"use strict";

/**
 * ひとことオーダーモーダル
 *
 * @public
 * @param  {string} selector
 * @return  {App.Behaviors.ModalWordOrderReplyMessage} self
 */
App.Behaviors.ModalWordOrderReplyMessage = function (selector) {
    var self = new App.Services.Observer(),
        $el = $(selector),
        $modalbg = $el.find('.js-modal-bg'),
        $modalClose = $el.find('.js-modal-close'),
        $reply = $el.find('#word-order-reply-message'),
        $word_orders_id = $('#js-active_word_order'),
        $to_user_id = $('#js-to-user-id'),
        $message_id = $('#js-message-id'),
        is_callApi = false;

        self._util = new App.Services.Util();
        self._validate = new App.Behaviors.Validator;
        self._fm = new App.Behaviors.FlashMessenger();
    /** ------------------------------
     *  Initialize
     ---------------------------------*/

    function init() {
        $el.find('.js-word-order-reply-message-trigger').on('click', function(e){
          e.preventDefault();
          
          var message = sanitaize($reply.val());
          if (!message.length) {
            self._fm.flashMessageDanger('メッセージを入力してください');
            $reply.val('');
            return;
          }
          
          if (is_callApi) { return; }
          is_callApi = true;
          
          orderApiCall();
        });

        $modalbg.on('click', function(){
          setTimeout(function(){
            $reply.val('');
          }, 500);
        });
        $modalClose.on('click', function(){
          setTimeout(function(){
            $reply.val('');
          }, 500);
        });
    }

    /** ------------------------------
     *  Private
     ---------------------------------*/
    /**
     *  orderApiCall
     *
     *  @function　apiの呼び出し
     *  @public
     *  @return {void}
     */
      var orderApiCall = function() {
        var valid = false,
            msg,
            type = 'POST',
            url = '/apiInternal/saveWordOrderReplyMessage',
            params = {
              'word_order_id': $word_orders_id.val(),
              'to_user_id': $to_user_id.val(),
              'comment' : sanitaize($reply.val()),
              'message_id' : $message_id.val(),
            },
            datatype = 'json';
            
        // APIコール
        self._util.ajax(type, url, params, datatype).done(function(res) {
            valid = (res.status === 0) ? true : false;
            msg = res.message;
            sendActionMsg(msg, valid);
                        
            if(!valid){
                self._fm.flashMessageDanger('入力に誤りがあります');
                is_callApi = false;
            } else {
                $('.js-modal-bg').click();
                self._fm.flashMessageWarning('メッセージ送信完了致しました。<br>マイページのメッセージ一覧より確認ができます。');
                setTimeout(function(){
                    location.href = '/top';
                }, 3000);
            }

        }).fail(function(res) {
            sendActionMsg('データの取得に失敗しました。');
            is_callApi = false;
        });
      }
    /**
     * sendActionMsg
     *
     * @function 通信結果のメッセージ
     * @private
     * @param {string} msg
     * @param {boolean} valid
     * @return {void}
     */
     function sendActionMsg(msg, valid) {
        var $msgBlock = $el.find('.js-msg-block');
        if(!valid) {
            $msgBlock.addClass('is-error');
            $msgBlock.hide().removeClass('is-error').empty().text(msg).fadeIn();
        } else {
            $msgBlock.hide().removeClass('is-error').empty().text('送信完了しました').fadeIn();
        }
    }

    /**
     * sanitaize
     *
     * @function 文字列のサニタイズ
     * @private
     * @param {string} str
     * @return {string}
     */
    function sanitaize(str) {
        //文字列先頭の空白削除
        str = str.replace(/^\s+/,"");
        //サニタイズ処理 <>&"`
        return str.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;').replace(/'/g, '&#39;');
    };
      
    /** ------------------------------
     *  Public
     ---------------------------------*/


    // ---------- init and export ----------
    init();
    return self;
};

}(this, this.document, this.App));

