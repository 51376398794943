/**
 *  PageViewCounter.js
 *
 *  @project      shareshima
 *  @since        2015/07/29
 *  @version      0.0.1
 *  @author       K.T.T (Kodama.Tsuji.Tamura)
 */

(function(win, doc, App){

"use strict";

/**
 * ページ閲覧数カウント
 *
 * @public
 * @return  {App.Behaviors.PageViewCounter} self
 */
App.Behaviors.PageViewCounter = function () {
    var self = new App.Services.Observer(),
        foodId = location.pathname.split("/")[location.pathname.split("/").length-1],
        data = {},
        ts = Math.floor( new Date().getTime() / 1000 ),
        diff,
        days = 60*60*24*1;

    self._util = new App.Services.Util();
    var ua = self._util.getuserAgent();

    /** ------------------------------
     *  Initialize
     ---------------------------------*/

    function init () {
        $.cookie.json = true;
        //cookieが無いので、新規作成する
        if(_.isUndefined($.cookie('pageView'))){
            savePageView(foodId,data,ts);
        //cookieがあるので、情報を照会する
        }else{
            data = $.cookie('pageView');
            //このページの閲覧履歴が存在している
            if(!_.isUndefined(data[foodId])){
                //前回のアクセスからの経過時間
                diff = ts - data[foodId];

                //前回の保存から1日以上経っている
                if(diff > days){
                    savePageView(foodId,data,ts);

                //前回の保存から1日経っていない
                }else{
                    //nothing to do
                    //console.log("notihing to do...");
                }
            //閲覧履歴が無いので、新規保存する
            }else{
                savePageView(foodId,data,ts);
            }
        }
        //console.log(data);
    }

    /** ------------------------------
     *  Private
     ---------------------------------*/
    /**
     *  savePageView
     *
     *  @function cookieに閲覧履歴を保存しAPIをコールする
     *  @private
     *  @param {int} id
     *  @param {object} data
     *  @param {int} ts タイムスタンプ
     *  @return {void}
     */
    function savePageView(id,data,ts) {
        var sucFunc = function(data){
            //console.log(data);
            data[id] = ts;
            //cookieを更新
            $.cookie('pageView',data,{expires: 30, path: '/'});
        },
        errFunc = function(data){
            //console.log(data);
        };

        //APIをコール
        callApi("/apiInternal/pageView",{food_id:id, ua:win.navigator.userAgent},sucFunc,errFunc);
    }

    /**
     *  callApi
     *
     *  @function APIとのやり取り
     *  @private
     *  @private
     *  @param {int} id
     *  @param {String} path
     *  @param {function} sucFunc
     *  @param {function} errFunc
     *  @return {void}
     */
    function callApi(path,ua,sucFunc,errFunc) {
        var settings = {
            type: "POST",
            dataType: "json",
            url : path,
            data: ua,
            success : function(data){
                if(data.status === 0){
                    sucFunc(data);
                }else{
                    errFunc(data);
                }
            }
        };
        $.ajax(settings);
    }

    /** ------------------------------
     *  Public
     ---------------------------------*/

    // ---------- init and export ----------
    init();
    return self;
};

}(this, this.document, this.App));

