/**
 *  NameSpace.js
 *
 *  @project      shareshima
 *  @since        2017/07/05
 *  @version      0.0.1
 *  @author       Tsuji
 */
(function(win, doc){

"use strict";

/**
 *  名前空間 : App
 *  このスクリプトに記述される処理は、
 *  全てこのオブジェクトのメンバとして記述し、
 *  唯一のグローバルオブジェクトとして扱う。
 *  @namespace App
 */
win.App = {
    // ページごとの実装はこちらへ
    Modules : {
        // ページの実装
        Page : {
            Foods: {},
            Users: {},
            Contacts: {},
            Supports: {},
            ClippedFoods: {},
            Resign: {},
            MyProfile: {},
            Blog: {},
            Exhibitions: {},
            NaganoMallTop: {},
            NaganoMallContacts: {},
            AddProduct: {},
            Product: {},
            KitchencarTop: {},
            KitchencarSalesMethod: {},
            KitchencarEvents: {},
            KitchencarGuide: {},
            KitchencarExhibitors: {},
            KitchencarAddEdit: {},
            NaganoMallProducts: {},
            NaganoMallCampaign: {},
            BusinessMeetings: {},
            Progresses: {},
        },
        // ページで使用されるクラスの実装
        Klass : {
            // ページごと使用するクラス初期化
            Teaser: {},
            Top: {},
            Foods: {},
            Users: {},
            MyPage: {},
            MyProfile: {},
            Profile: {},
            Messages: {},
            EditAddress: {},
            Contacts: {},
            Supports: {},
            Resign: {},
            Blog: {},
            Exhibitions: {},
            NaganoMallTop: {},
            AddProduct: {},
            Product: {},
            KitchencarTop: {},
            KitchencarSalesMethod: {},
            KitchencarEvents: {},
            KitchencarGuide: {},
            KitchencarExhibitors: {},
            KitchencarAddPreferredDate: {},
            NaganoMallContacts: {},
            NaganoMallProducts: {},
            NaganoMallCampaign: {},
            NaganoMallEcSites: {},
            NaganoMallBusiness: {},
            BusinessMeetings: {},
            Progresses: {},
        },
        SuperPage  : function () {}, // Page以下の実装のスーパークラス
        SuperKlass : function () {}  // Klass以下の実装のスーパークラス
    },
    // DOM操作を伴う再利用性の高い実装はこちらへ
    Behaviors : {},
    // DOM操作を伴わない再利用性の高い実装はこちらへ
    Services  : {
        ModuleLoader: function () {},
        Observer: function () {},
        Util : function () {}
    },
    // 定数は入れませんか？
    Constants : {
        Host : "",
        IS_ADD : false,
        IS_EDIT : false
    }
};

}(this, this.document));
