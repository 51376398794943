/**
 *  SideNav.js
 *
 *  @project      nagano
 *  @since        2019/11/21
 *  @version      0.0.1
 *  @author       Ishibashi
 */

(function(win, doc, App){

"use strict";

/**
 * SP用 サイドメニュー 表示
 *
 * @public
 * @param  {string} selector
 * @return  {App.Behaviors.SideNav} self
 */
App.Behaviors.SideNav = function (selector) {
    var self = new App.Services.Observer(),
        $body = $('body'),
        $trigger = $(selector),
        $menu = $('.js-side-nav-contents'),
        hh = $('.js-header').height() + $('.header-read').outerHeight(),
        scrollPos,
        $bg = $('.js-side-overlay'),
        $targetWrapSp = $('.js-pinpoint-serach-child-wrap-sp'),
        show_flag = false;
        self._util = new App.Services.Util();

    /** ------------------------------
     *  Initialize
     ---------------------------------*/

    function init() {
        //右下メニュー用ボタンクリック時の処理
        $trigger.on('click', function(e){
          e.preventDefault();
            if($menu.hasClass('is-active')) {
                //メニュー非表示の処理
                sideNavClose();
                
            } else {
                //メニュー表示の処理
                if (show_flag) { return; }
                show_flag = true;
                sideNavOpen();
            }
        });
        
        //背景クリックで閉じる処理
        $bg.on('click', function() {
          $trigger.click();
        });
        
        //enTop メニュボタン表示
        if (window.innerWidth < 768) {
            if ($('.js-kv-side-nav-btn').length > 0) {
                $(window).on('scroll',function(){
                    var pos = $('.js-kv-side-nav-btn').offset().top;                    
                    if (pos + $('.js-kv-side-nav-btn').outerHeight() > $(window).scrollTop()) {
                        $('.btn-side-sp').removeClass('is-active');
                    }else {
                        $('.btn-side-sp').addClass('is-active');
                    }
                });
            } else {
                $('.btn-side-sp').addClass('is-active');
            }
        }
        
        
        $(window).on('resize', function(){
            if (window.innerWidth > 767) {
                if ($menu.hasClass('is-active')) {
                    sideNavClose()
                }
            }
        });
    }

    /** ------------------------------
     *  Private
     ---------------------------------*/
    /**
     *  sideNavOpen
     *
     *  @function　サイドナビを表示にする処理
     *  @public
     *  @return {void}
     */
    var sideNavOpen = function () {
        $menu.addClass('is-active');
        $bg.fadeIn(200);
        
        scrollPos = $(window).scrollTop();
        $body.css({'top': -(scrollPos) });
        $body.addClass('is-fixed');
        
        //ヘッダーが表示している場合はヘッダー分ずらし処理
        if (scrollPos < hh) {
          var postop = hh - scrollPos;
          $menu.css({'top': postop, 'height': 'calc(100% - '+ postop +'px)'}); 
          $bg.css({'top': postop, 'height': 'calc(100% - '+ postop +'px)'}); 
        } else {
          $menu.css({'top': 0, 'height': '100%'}); 
          $bg.css({'top': 0, 'height': '100%'}); 
        }
     }
    /**
     *  sideNavClose
     *
     *  @function　サイドナビを非表示にする処理
     *  @public
     *  @return {void}
     */
    var sideNavClose = function () {
        $menu.removeClass('is-active');
        $bg.fadeOut(200, function(){
            if ($menu.hasClass('is-sub')) {
                $menu.removeClass('is-sub');
            }
            setTimeout(function(){
              if ($targetWrapSp.hasClass('is-active')) {
                  $targetWrapSp.removeClass('is-active');
              }
            }, 200);
            setTimeout(function(){
              $body.removeClass('is-fixed');
              $body.css({'top': 0});
              $(window).scrollTop(scrollPos);
              $('.js-side-nav-contents-inner').scrollTop(0);
              $menu.css({'top': 0, 'height': '100%'}); 
              $bg.css({'top': 0, 'height': '100%'});
              show_flag = false;
            }, 500);
        });
     }
      
    /** ------------------------------
     *  Public
     ---------------------------------*/


    // ---------- init and export ----------
    init();
    return self;
};

}(this, this.document, this.App));

