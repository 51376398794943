/**
 *  SearchCategoryBuild.js
 *
 *  @project      nagano
 *  @since        2019/01/10
 *  @version      0.0.1
 *  @author       Ishibashi
 */

(function(win, doc, App){

"use strict";

/**
 * 食品カテゴリのselectの実装
 *
 * @public
 * @param  {string} selector
 * @return  {App.Behaviors.SearchCategoryBuild} self
 */
App.Behaviors.SearchCategoryBuild = function (selector) {
    var self = new App.Services.Observer(),
        $el = $(selector),
        $catL = $el.find('.js-food-category-l'),
        $catM = $el.find('.js-food-category-m'),
        $catS = $el.find('.js-food-category-s'),
        $catSS = $el.find('.js-food-category-ss');
                
        var dataL = SearchApp.masterFoodCategories,
            dataM = SearchApp.masterFoodSubcategories,
            dataS = SearchApp.masterFoodSubsubcategories,
            dataSS = SearchApp.masterFoodSubsubsubcategories;
                    
        self._util = new App.Services.Util();

    /** ------------------------------
     *  Initialize
     ---------------------------------*/

    function init() {
 
      $catM.prop("disabled", true);
      $catS.prop("disabled", true);
      $catSS.prop("disabled", true);
      
      //大分類の生成
      $.each(dataL, function(i){
        $catL.append($('<option>').html(dataL[i]).val(i));
      });

      //selectの表示制御
      $catL.selectOrDie({
        size: 6,
        onChange: function() {
          buildSelect(dataM, $catM, $(this).val(), 'M');
          if ($(this).val()) {
            $catM.parent('.sod_select').parent('.js-select').addClass('is-active');
            $catM.selectOrDie("destroy");
            $catM.prop("disabled", false);

            changeCategorySub();
            resetSelect([$catS, $catSS]);
            changeCategorySubSub();
            changeCategorySubSubSub();
          } else {
            resetSelect([$catM, $catS, $catSS]);
            changeCategorySub();
            changeCategorySubSub();
            changeCategorySubSubSub();
          }
          App.Behaviors.SelectBox($(this));
        }
      });

      changeCategorySub();

      changeCategorySubSub();
      
      changeCategorySubSubSub();
            
      //カテゴリ選択されてるか
      var getVal = getUrlVars();
      if (getVal['category']){
        $catL.val(getVal['category']);
        $catL.change();
      }
      if (getVal['subcategory']){
        $catM.val(getVal['subcategory']);
        $catM.change();
      }
      if (getVal['subsubcategory']){
        $catS.val(getVal['subsubcategory']);
        $catS.change();
      }
      if (getVal['subsubsubcategory']){
        $catSS.val(getVal['subsubsubcategory']);
        $catSS.change();
      }      
    }

    /** ------------------------------
     *  Private
     ---------------------------------*/

    /**
     *  changeCategorySub
     *
     *  @function サブカテゴリーの処理
     *  @private
     *  @return {void}
     */
     
     var changeCategorySub = function() {
        $catM.selectOrDie({
          size: 6,
          onChange: function() {            
            buildSelect(dataS, $catS, $(this).val(), 'S');
            if ($(this).val()) {
                var flag = false,
                    val = $(this).val();
                $.each(dataS, function(i, data){
                    if (data.cat_id == val) {
                       flag = true;
                    }
                });
              if (flag) {
                $catS.parent('.sod_select').parent('.js-select').addClass('is-active');
                $catS.selectOrDie("destroy");
                $catS.prop("disabled", false);
                changeCategorySubSub();
                
                resetSelect([$catSS]);
                changeCategorySubSubSub();
              } else {
                resetSelect([$catS]);
                resetSelect([$catSS]);
                changeCategorySubSub();
                changeCategorySubSubSub();
              }
            } else {
              resetSelect([$catS, $catSS]);
              changeCategorySubSub();
              changeCategorySubSubSub();
            }
            App.Behaviors.SelectBox($(this));
          }
        });
     }

    /**
     *  changeCategorySubSub
     *
     *  @function サブサブカテゴリーの処理
     *  @private
     *  @return {void}
     */
     var changeCategorySubSub = function() {
        $catS.selectOrDie({
          size: 6,
          onChange: function() {
            buildSelect(dataSS, $catSS, $(this).val(), 'SS');
            if ($(this).val()) {
                var flag = false,
                    val = $(this).val();
                $.each(dataSS, function(i, data){
                    if (data.cat_id == val) {
                       flag = true;
                    }
                });
              if (flag) {
                  $catSS.parent('.sod_select').parent('.js-select').addClass('is-active');
                  $catSS.selectOrDie("destroy");
                  $catSS.prop("disabled", false);
                  changeCategorySubSubSub();
              }  
            } else {
              resetSelect([$catSS]);
              changeCategorySubSubSub();
            }
            App.Behaviors.SelectBox($(this));
          }
        });
     }

    /**
     *  changeCategorySubSub
     *
     *  @function サブサブカテゴリーの処理
     *  @private
     *  @return {void}
     */
     var changeCategorySubSubSub = function() {
        $catSS.selectOrDie({
          size: 6,
          onChange: function() { App.Behaviors.SelectBox($(this)); }
        });
     }

     
    /**
     *  buildSelect
     *
     *  @function 選択された値でselect生成
     *  @private
     *  @param  {obj} data
     *  @param  {dom} $select
     *  @param  {string} val
     *  @param  {string} cat
     *  @return {void}
     */
     var buildSelect = function(data, $select, val, cat) {
       if (val == '') { return; }
       var plh = '中カテゴリ選択';
       if (cat == 'S') {
          plh = '小カテゴリ選択';
       } else if (cat == 'SS') {
          plh = '細カテゴリ選択';
       }
        $select.find('option').remove();
        $select.append($('<option>').html(plh).val(''));

        $.each(data, function(i){
          if (data[i].cat_id == val) {
             $select.append($('<option>').html(data[i].text).val(i));
          }
        });
        $select.change(); 
     }

    /**
     *  resetSelect
     *
     *  @function select初期化
     *  @private
     *  @param  {arr} data
     *  @return {void}
     */
     var resetSelect = function(data) {
        $.each(data, function(i){
            data[i].selectOrDie("destroy");
            data[i].prop("disabled", true);
            data[i].val('');
            data[i].parent('.sod_select').parent('.js-select').removeClass('is-active');
        });      
     }
     
    /**
     *  getUrlVars
     *
     *  @function GETパラメータを配列にして返す
     *  @private
     *  @return {obj}
     */
      var getUrlVars = function(){
        var vars = {}; 
        var param = location.search.substring(1).split('&');
        for(var i = 0; i < param.length; i++) {
            var keySearch = param[i].search(/=/);
            var key = '';
            if(keySearch != -1) key = param[i].slice(0, keySearch);
            var val = param[i].slice(param[i].indexOf('=', 0) + 1);
            if(key != '') vars[key] = decodeURI(val);
        } 
        return vars; 
      }

    /** ------------------------------
     *  Public
     ---------------------------------*/


    // ---------- init and export ----------
    init();
    return self;
};

}(this, this.document, this.App));

