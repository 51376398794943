/**
 *  Modal.js
 *
 *  @project      shareshima
 *  @since        2016/06/12
 *  @version      0.0.1
 *  @author       Tsuji
 */

(function(win, doc, App){

"use strict";

/**
 * モーダル表示
 *
 * @public
 * @return {App.Behaviors.Modal} self
 */
App.Behaviors.Modal = function (selector) {
    var self = new App.Services.Observer(),
        $modalTrigger = $(selector),
        $modal = $('.js-modal'),
        $modalbg = $modal.find('.js-modal-bg'),
        $modalClose = $modal.find('.js-modal-close');
        
    self._util = new App.Services.Util();

    /** ------------------------------
     *  Initialize
     ---------------------------------*/
    function init () {
      
      //モーダル背景クリックで非表示
      $modalbg.on('click',function(){
        modalClase();
      });
      //モーダル閉じるボタンクリックで非表示
      $modalClose.on('click',function(){
        modalClase();
      });
    
      //モーダル表示
      $modalTrigger.on('click',function(e){
        e.preventDefault();
        
        var target = $(this).attr('href');        
    
        if($modal.is(':visible')){
          modalFormInit();
          $('.js-modal:visible').hide();
          $(target).show();
        } else {
          modalOpen($(target));
        }
      });

    }

    /** ------------------------------
     *  Private
     ---------------------------------*/
    /**
     *  modalOpen
     *
     *  @function　モーダル表示処理
     *  @public
     *  @param {string} $el モーダル要素
     *  @return {void}
     */
      var modalOpen = function($el) {
        $el.fadeIn(500);
      }

    /**
     *  modalClase
     *
     *  @function　モーダル非表示処理
     *  @public
     *  @param {string} $el モーダル要素
     *  @return {void}
     */
      var modalClase = function() {
        $modal.fadeOut(500, function() {
            setTimeout(function(){
                modalFormInit();
            }, 1000);
        });
      }
    /**
     *  modalFormInit
     *
     *  @function　モーダル内の入力初期化
     *  @public
     *  @return {void}
     */
      var modalFormInit = function() {
          $modal.each(function() {
              if ($(this).data('type') == 'noreset') return;
              $(this).find('input').val('');
              $(this).find('input[type="checkbox"]').prop('checked', false);
              $(this).find('input').removeClass('form-error');
              $(this).find('.js-msg-block').hide();
          });
      }
    /** ------------------------------
     *  Public
     ---------------------------------*/
     
    // ---------- init and export ----------
    init();
    return self;
};

}(this, this.document, this.App));
