/**
 *  InputNumber.js
 *
 *  @project      shareshima
 *  @since        2017/12/21
 *  @version      0.0.1
 *  @author       Tsuji
 */

(function(win, doc, App){

"use strict";

/**
 * 入力値を半角数字のみにする
 *
 * @public
 * @param  {string} selector
 * @return  {App.Behaviors.InputNumber} self
 */
App.Behaviors.InputNumber = function (selector) {
    var self = new App.Services.Observer(),
        $el = $(selector);

    /** ------------------------------
     *  Initialize
     ---------------------------------*/

    function init() {
          
      $el.each(function(){
          $(this).on('input', function() {
              // 半角変換
              var halfVal = $(this).val().replace(/[！-～]/g,
                  function (tmpStr) {
                      // 文字コードをシフト
                      return String.fromCharCode(tmpStr.charCodeAt(0) - 0xFEE0);
                  }
              );
              // 数字以外の不要な文字を削除
              $(this).val(halfVal.replace(/[^0-9]/g, ''));
          });
      });
    }

    /** ------------------------------
     *  Private
     ---------------------------------*/
      
    /** ------------------------------
     *  Public
     ---------------------------------*/


    // ---------- init and export ----------
    init();
    return self;
};

}(this, this.document, this.App));

