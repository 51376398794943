/**
 *  CategoryMenuEn.js
 *
 *  @project      nagano
 *  @since        2021/01/05
 *  @version      0.0.1
 *  @author       ishibashi
 */

(function(win, doc, App){

"use strict";

/**
 * 英語用カテゴリメニュー
 *
 * @public
 * @param {string} selector
 * @return {App.Behaviors.CategoryMenuEn}
 */
App.Behaviors.CategoryMenuEn = function (selector) {
    var self = new App.Services.Observer(),
        $el = $(selector);
        
        self._util = new App.Services.Util();

    /** ------------------------------
     *  Initialize
     ---------------------------------*/
    function init () {
        //サブカテゴリ表示        
        $el.find('.js-categoryItem').on('mouseenter',function(){
            $el.find('.js-categoryItem').removeClass('is-current');
            $el.find('.js-sub-category').hide();
            
            $el.find('.js-sub-category').find('.js-sub-subCategoryItem').hide().removeClass('is-show');
            $el.find('.js-sub-category').each(function(){
                $(this).find('.js-sub-subCategoryItem').eq(0).show();
                $(this).find('.js-sub-subCategoryItem').eq(0).addClass('is-show');
            });            
            
            if(!$(this).hasClass('is-current')){
                $(this).addClass('is-current');
                //サブサブカテゴリ リセット
                $el.find('.js-sub-categoryItem').removeClass('is-current');
                $el.find('.js-sub-subCategoryItem')
                
                //表示
                var h = $(this).find('>a').outerHeight();
                var pos = $(this).position().top;
                $(this).find('.js-sub-category').css({'top': h + pos + 3});
                $(this).find('.js-sub-category').fadeIn(300);
            }
        });
        //サブカテゴリ非表示
        $el.on('mouseleave',function(){
            $el.find('.js-categoryItem').removeClass('is-current');
            $el.find('.js-sub-category').fadeOut();
        });
        
        //サブサブカテゴリ
        $el.find('.js-sub-categoryItem').on('mouseenter',function(){
            $(this).addClass('is-current');
            var index = $(this).parents('.js-sub-category').find('.js-sub-categoryItem').index($(this));
            $('.js-sub-subCategoryItem').hide().removeClass('is-show');
            $(this).parents('.js-sub-category').find('.js-sub-subCategoryItem').eq(index).show();
             $(this).parents('.js-sub-category').find('.js-sub-subCategoryItem').eq(index).addClass('is-show');
        });
        
        
    }
     

    /** ------------------------------
     *  Public
     ---------------------------------*/

    // ---------- init and export ----------
    init();
    return self;
};

}(this, this.document, this.App));

