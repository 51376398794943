/**
 *  HorizontalScroll.js
 *
 *  @project      shareshima
 *  @since        2015/07/31
 *  @version      0.0.1
 *  @author       K.T.T (Kodama.Tsuji.Tamura)
 */

(function(win, doc, App){

"use strict";

/**
 * 食品コマの横スライダーブロック
 *
 * @public
 * @requires slick.js
 * @param {string} selector
 * @param {obj} op
 * @return {App.Behaviors.HorizontalScroll}
 */
App.Behaviors.HorizontalScroll = function (selector) {
    var self = new App.Services.Observer(),
        $el = $(selector);
        
        self._util = new App.Services.Util();

    /** ------------------------------
     *  Initialize
     ---------------------------------*/
    function init () {
      $el.mCustomScrollbar({
        axis:"x",
        theme:"dark-thin",
        autoExpandScrollbar:true,
        advanced:{
          autoExpandHorizontalScroll:true
        },
        mouseWheel:{
          axis: "x",
          invert: true
        },
        //autoHideScrollbar: true,
        callbacks:{
          onInit: function(){
            var $this = $(this);
            var $slide = $this.find('.mCSB_container');
            var itemW = $this.find('.js-scroll-block__item').eq(1).outerWidth(true);
            var itemFW = $this.find('.js-scroll-block__item').eq(0).outerWidth(true);
            var itemL = $this.find('.js-scroll-block__item').length;    
          },
          onScrollStart: function() {
            var $this = $(this);
          },
          onScroll: function() {
            var $this = $(this);
          },
          onTotalScroll: function() {
            var $this = $(this);
          },
          onTotalScrollBack: function() {
            var $this = $(this);
          },
        }
      });
    }
     

    /** ------------------------------
     *  Public
     ---------------------------------*/

    // ---------- init and export ----------
    init();
    return self;
};

}(this, this.document, this.App));

