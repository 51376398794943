/**
 *  ModalSignUp.js
 *
 *  @project      shareshima
 *  @since        2017/12/21
 *  @version      0.0.1
 *  @author       Tsuji
 */

(function(win, doc, App){

"use strict";

/**
 * ログインモーダル
 *
 * @public
 * @param  {string} selector
 * @return  {App.Behaviors.ModalSignUp} self
 */
App.Behaviors.ModalSignUp = function (selector) {
    var self = new App.Services.Observer(),
        $el = $(selector),
        $form = $el.find('.js-signup-form'),
        $finish = $el.find('.js-signup-finish'),
        $email = $el.find('#signup-mail'),
        $password = $el.find('#signup-password'),
        $passwordConf = $el.find('#signup-password-conf'),
        $signupMsg = $el.find('.js-signup-msg'),
        $signupEmailMsg = $el.find('.js-signup-email-msg'),
        $signupPasswordMsg = $el.find('.js-signup-password-msg'),
        $signupPasswordConfMsg = $el.find('.js-signup-password-conf-msg'),
        signUpToken,
        is_callApi = false;

        self._util = new App.Services.Util();
        self._validate = new App.Behaviors.Validator;
        self._fm = new App.Behaviors.FlashMessenger();
    /** ------------------------------
     *  Initialize
     ---------------------------------*/

    function init() {
      
        if($('body').hasClass('is-en') || $('body').hasClass('is-ch') || $('body').hasClass('is-kitchencar') || $('body').hasClass('is-nagano-mall')){
            $form.find('input').on('change', function(){
                if ($email.val() && $password.val() && $passwordConf.val()) {
                    $el.find('.js-signup-trigger').removeClass('is-disabled');
                } else {
                    $el.find('.js-signup-trigger').addClass('is-disabled');
                }
            });

            $el.find('.js-signup-trigger').on('click', function(e){
              e.preventDefault();
              if($(this).hasClass('is-disabled')) { return; }
              if (is_callApi) { return; }
              is_callApi = true;
              if (!signUpToken) {
                //トークン取得
                signUpTokenApiCall();
              } else {
                //サインアップapi呼び出し
                signUpApiCall();
              }
            });
        } else {
            $el.find('.js-signup-trigger').on('click', function(e){
              e.preventDefault();
              if (is_callApi) { return; }
              is_callApi = true;          
              if (!signUpToken) {
                //トークン取得
                signUpTokenApiCall();
              } else {
                //サインアップapi呼び出し
                signUpApiCall();
              }
            }); 
        }
    }

    /** ------------------------------
     *  Private
     ---------------------------------*/
    /**
     *  signUpTokenApiCall
     *
     *  @function　トークンapiの呼び出し
     *  @public
     *  @return {void}
     */
     var signUpTokenApiCall = function() {
        var valid = false,
            type = 'GET',
            url = '/apiInternal/getSignUpTokenKey',
            params = {
            },
            datatype = 'json';
        // APIコール
        self._util.ajax(type, url, params, datatype).done(function(res) {
            valid = (res.status === 0) ? true : false;
            
            if(!valid){
                sendActionMsg('データの取得に失敗しました、時間をおいて再度お試しください、申し訳ありません');
            }
            if(valid){
              //トークンセット
              signUpToken = res.data.token;
              //サインアップapi呼び出し
              signUpApiCall();
            }
            is_callApi = false;
            
        }).fail(function(res) {
            sendActionMsg('エラーが発生しました、時間を置いてお試し下さい、申し訳ありません');
            is_callApi = false;
        });
     }
     
    /**
     *  signUpApiCall
     *
     *  @function　apiの呼び出し
     *  @public
     *  @return {void}
     */
      var signUpApiCall = function() {
        var url = '/apiInternal/signup';
        if ($el.attr('id') == 'modal-signup-supplier') {
          url = '/apiInternal/signupSupplier';
        }
        if ($('body').hasClass('is-kitchencar')){
           if ($el.attr('id') == 'modal-signup-supplier') {
              url = '/apiInternal/kitchencarSignupSupplier';
           } else {
              url = '/apiInternal/kitchencarSignup';
           }
        } else if ($('body').hasClass('is-nagano-mall')){
            url = '/apiInternal/naganoMallSignupSupplier';
        }

        var valid = false,
            msg,
            type = 'POST',
            params = {
              "email" : $email.val(),
              "password" : $password.val(),
              "password_confirm" : $passwordConf.val(),
              "token" : signUpToken,
            },
            datatype = 'json';

        // APIコール
        self._util.ajax(type, url, params, datatype).done(function(res) {
            valid = (res.status === 0) ? true : false;
            signUpMsg(res);
            
            if(!valid){
                self._fm.flashMessageDanger('入力に誤りがあります');
            }
            if(valid){
                // redirect
                if(self._util.hasAttr($el, 'data-foods')) {//TODO:今回は必要か？
                    location.reload(true);
                } else {
                    $form.hide();
                    $finish.show();
                    setTimeout(function(){
                      if($('body').hasClass('is-en')) {
                        location.href = '/en';
                      } else if($('body').hasClass('is-ch')) {
                        location.href = '/ch';
                      } else if ($('body').hasClass('is-kitchencar')) {
                        location.href = '/kitchencar';
                      } else if ($('body').hasClass('is-nagano-mall')) {
                        location.href = '/nagano-mall';
                      } else {
                        location.href = '/top';
                      }
                    }, 2000);
                }
            }
            is_callApi = false;
            
        }).fail(function(res) {
            sendActionMsg('データの取得に失敗しました。');
            is_callApi = false;
        });
      }

    /**
     * signUpMsg
     *
     * @function サインアップ時のメッセージ
     * @param {object} res
     * @param {string} selector
     * @return {void}
     */
    function signUpMsg(res) {
        var $msgBlock = $el.find('.js-msg-block'),
            $input = $el.find('.form-control');
        $msgBlock.hide().removeClass('is-error').empty();
        $input.removeClass('form-error');

        $signupMsg.text(res.message).fadeIn();

        if(res.status !== 0) {
            $msgBlock.addClass('is-error');
            
            if(res.data) {
              signUpErrorMsg($signupEmailMsg, res.data.errors.email, $email);
              signUpErrorMsg($signupPasswordMsg, res.data.errors.password, $password);
              signUpErrorMsg($signupPasswordConfMsg, res.data.errors.password_confirm, $passwordConf);
            }
        }
    }
    
    /**
     * signUpErrorMsg
     *
     * @function サインアップ時のエラーメッセージ表示
     * @param {object} res
     * @param {string} selector
     * @return {void}
     */
    function signUpErrorMsg($msg, error, $input) {
        var msg;
        
        if (error) {
          $msg.addClass('is-error');
          $input.addClass('form-error');
          
          $.each(error, function(index, txt){
            if (msg) {
              msg += '<br>' + txt;
            } else {
              msg =  txt;
            }
          });
          
          $msg.html(msg).fadeIn();
        }
    }

    /**
     * sendActionMsg
     *
     * @function 通信結果のメッセージ
     * @private
     * @param {string} msg
     * @param {boolean} valid
     * @return {void}
     */
     function sendActionMsg(msg, valid) {
        $signupMsg.hide().removeClass('is-error').empty().text(msg).fadeIn();
        if(!valid) {
            $signupMsg.addClass('is-error');
        }
    }
      
    /** ------------------------------
     *  Public
     ---------------------------------*/


    // ---------- init and export ----------
    init();
    return self;
};

}(this, this.document, this.App));

