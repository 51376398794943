/**
 *  ModalLogin.js
 *
 *  @project      shareshima
 *  @since        2017/12/21
 *  @version      0.0.1
 *  @author       Tsuji
 */

(function(win, doc, App){

"use strict";

/**
 * ログインモーダル
 *
 * @public
 * @param  {string} selector
 * @return  {App.Behaviors.ModalLogin} self
 */
App.Behaviors.ModalLogin = function (selector) {
    var self = new App.Services.Observer(),
        $el = $(selector),
        $email = $el.find('#login-mail'),
        $password = $el.find('#login-password'),
        is_callApi = false;

        self._util = new App.Services.Util();
        self._validate = new App.Behaviors.Validator;
        self._fm = new App.Behaviors.FlashMessenger();
    /** ------------------------------
     *  Initialize
     ---------------------------------*/

    function init() {
      
        if($('body').hasClass('is-en') || $('body').hasClass('is-nagano-mall') ){
            $('#login-mail, #login-password').on('change', function(){
                if ($el.find('#login-password').val() && $el.find('#login-mail').val()) {
                    $el.find('.js-login-trigger').removeClass('is-disabled');
                } else {
                    $el.find('.js-login-trigger').addClass('is-disabled');
                }
            });

            $el.find('.js-login-trigger').on('click', function(e){
              e.preventDefault();
              if ($(this).hasClass('is-disabled')) { return; }
              if (is_callApi) { return; }
              is_callApi = true;
              loginApiCall();
            });

        } else {
            $el.find('.js-login-trigger').on('click', function(e){
              e.preventDefault();
              if (is_callApi) { return; }
              is_callApi = true;
              
              loginApiCall();
            });
        }
    }

    /** ------------------------------
     *  Private
     ---------------------------------*/
    /**
     *  loginApiCall
     *
     *  @function　apiの呼び出し
     *  @public
     *  @return {void}
     */
      var loginApiCall = function() {
        var valid = false,
            msg,
            type = 'POST',
            url = '/apiInternal/login',
            params = {
              "email" : $email.val(),
              "password" : $password.val()
            },
            datatype = 'json';
            //モールトップならログイン後キャンペーンページ遷移用のパラメータを追加
            if ($('body').hasClass('top is-nagano-mall')) {
                if (openLoginModal) {
                    params.open_campaign_id = 1;
                }
            }
        // APIコール
        self._util.ajax(type, url, params, datatype).done(function(res) {
            valid = (res.status === 0) ? true : false;
            msg = res.message;
            sendActionMsg(msg, valid);
            //console.log('res %O', res)
            
            if(!valid){
                self._fm.flashMessage('入力に誤りがあります');
            }
            
            if(valid){
                //if(res.data.verify_email && res.data.examStatus) {
                if(res.data.verify_email) {
                    // redirect
                    location.reload(true);
                } else {
                    alert('届いているメールのURLをクリックしてメール認証、もしくはプロフィール入力して会員申請を行ってください');
                    // redirect
                    location.reload(true);
                }
            }
            is_callApi = false;
            
        }).fail(function(res) {
            sendActionMsg('データの取得に失敗しました。');
            is_callApi = false;
        });
      }
    /**
     * sendActionMsg
     *
     * @function 通信結果のメッセージ
     * @private
     * @param {string} msg
     * @param {boolean} valid
     * @return {void}
     */
     function sendActionMsg(msg, valid) {
        var $msgBlock = $el.find('.js-msg-block');
            $msgBlock.hide().removeClass('is-error').empty().text(msg).fadeIn();
        if(!valid) {
            $msgBlock.addClass('is-error');
        }
    }
      
    /** ------------------------------
     *  Public
     ---------------------------------*/


    // ---------- init and export ----------
    init();
    return self;
};

}(this, this.document, this.App));

