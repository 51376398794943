/**
 *  Tab.js
 *
 *  @project      shareshima
 *  @since        2017/12/26
 *  @version      0.0.2
 *  @author       Tsuji.Ishibashi
 */

(function(win, doc, App){

"use strict";

/**
 * タブメニュー切り替え
 *
 * @public
 * @param {string} selector
 * @return {App.Behaviors.Tabl}
 */
App.Behaviors.Tab = function (selector) {
    var self = new App.Services.Observer(),
        $el = $(selector);

    /** ------------------------------
     *  Initialize
     ---------------------------------*/
    function init () {
      $el.each(function(){
        var $tabTrigger = $(this).find('.js-tab-trigger').find('a');
        var $tabContents = $(this).find('.js-tab-contents').find('> div');
    
        $tabTrigger.on('click', function(e){
          e.preventDefault();
          if($(this).hasClass('is-active')){ return; }
          var index = $tabTrigger.index($(this));
    
          $tabTrigger.removeClass('is-active');
          $tabContents.removeClass('is-active');
    
          $tabTrigger.eq(index).addClass('is-active');
          $tabContents.eq(index).addClass('is-active');
        });
      });

    }

    /** ------------------------------
     *  Private
     ---------------------------------*/

    /** ------------------------------
     *  Public
     ---------------------------------*/

    // ---------- init and export ----------
    init();
    return self;
};

}(this, this.document, this.App));

