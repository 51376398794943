/**
 *  Hamburger.js
 *
 *  @project      shareshima
 *  @since        2019/01/21
 *  @version      0.0.1
 *  @author       Tsuji.Ishibashi.Izumi
 */

(function(win, doc, App){

"use strict";

/**
 * ハンバーガーメニュー
 *
 * @public
 * @param {string} selector
 * @return {App.Behaviors.Hamburger}
 */
App.Behaviors.Hamburger = function (selector) {
    var self = new App.Services.Observer(),
        $el = $(selector),
        $overwrap = $('.header__menuOverwrap'),
        $content = $('.header__menu');
    /** ------------------------------
     *  Initialize
     ---------------------------------*/
    function init () {
        $el.off('click');
        $el.on('click',function(e){
            if($el.hasClass('is-active')){
                $overwrap.hide();
                $content.fadeOut();
                $el.removeClass('is-active');
            } else {
                $overwrap.show();
                $content.fadeIn();
                $el.addClass('is-active');
            }
        });

        $overwrap.on('click',function(e){
            $overwrap.hide();
            $content.fadeOut();
            $el.removeClass('is-active');
        });
    }

    /** ------------------------------
     *  Private
     ---------------------------------*/

    /** ------------------------------
     *  Public
     ---------------------------------*/

    // ---------- init and export ----------
    init();
    return self;
};

}(this, this.document, this.App));

