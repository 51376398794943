/**
 *  ModuleLoader.js
 *
 *  @project      shareshima
 *  @since        2015/07/14
 *  @version      0.0.1
 *  @author       K.T.T (Kodama.Tsuji.Tamura)
 */

(function(win, doc, App){

"use strict";

/**
 * 名前空間App.Modules下のクラスを初期化する
 *
 * @public
 * @param  {object} options
 * @return  {object} self
 */
App.Services.ModuleLoader = function (options) {
    var self = {},
        util = new App.Services.Util();
        
    /** ------------------------------
     *  Initialize
     ---------------------------------*/

    function init () {
    }

    /** ------------------------------
     *  Private
     ---------------------------------*/

    /**
     *  コンストラクタ引数options.rulesの対応表に従って、
     *  URLのパターンがマッチする場合、対応するPageKlassを初期化する
     *
     *  @private
     *  @return {boolean}
     */
    function matchByPatterns() {
        // patternsに指定されたURLパターンによる初期化
        var p = location.pathname,
            patterns = options.rules || [],
            i = 0,
            l = patterns.length,
            j = 0,
            m = 0;
        for (; i < l; i++) {
            m = patterns[i].pattern.length;
            for (j = 0; j < m; j++) {
                if (patterns[i].pattern[j].test(p)) {
                    console.log('Initialize', patterns[i].page);
                    new App.Modules.Page[patterns[i].page]();
                    return true;
                }
            }
        }
        return false;
    }

    /**
     *  location.pathnameからApp.Modules下のPageKlassを
     *  探し、存在する場合初期化する(e.g./hoge/fugaの構造の時
     *  App.Modules.[なんでもOK].HogeFugaPageを探し初期化)
     *
     *  @private
     *  @return {boolean}
     */
    function matchByPathName() {
        // 現在のpathnameから該当するApp.Page.Klassの初期化
        var p = location.pathname,
            items = p.split("/");
        if (items.length < 2) {
            returnflase;
        }
        var controller_name = util.camelize(items[1]),
            action_name = util.camelize(items[2]),
            klass = App.Modules[controller_name] && App.Modules[controller_name][action_name + 'Page'];
        if (klass) {
            new klass();
            return true;
        }
        return false;
    }

    /**
     *  
     *  コンストラクタ引数のoptions.not_matchの
     *  PageKlassを探し、存在する場合初期化する
     *
     *  @private
     *  @return {boolean}
     */
    function matchNothing() {
        var klass = App.Modules.Page[options.not_match];
        if (klass) {
            new klass();
            return true;
        }
        return false;
    }

    /** ------------------------------
     *  Public
     ---------------------------------*/

    /**
     *  既定のルールにより、App.Modules以下のクラスを初期化する
     *  ルール1: コンストラクタ引数で渡されたpatternsのURLとPageKlassの対応表からロード
     *  ルール2: URLのpathnameからController名とAction名のアッパーキャメルケースのPageKlassがある場合ロード
     *  ルール3: いずれにもマッチしない場合、options.notmatchで指定したPageKlassをロード
     *  
     *  @public
     *  @return {boolean}
     */
    self.load = function() {
        return matchByPatterns() || matchByPathName() || matchNothing()
    };

    // ---------- init and export ----------
    init();
    return self;
};

}(this, this.document, this.App));
