/**
 *  Tag.js
 *
 *  @project      nagano
 *  @since        2019/11/13
 *  @version      0.0.1
 *  @author       Ishibashi
 */

(function(win, doc, App){

"use strict";

/**
 *  a要素内のタグクリック時の処理
 *
 * @public
 * @param {string} selector
 * @return {App.Behaviors.Tag}
 */
App.Behaviors.Tag = function (selector) {
    var self = new App.Services.Observer(),
        $el = $(selector);

    /** ------------------------------
     *  Initialize
     ---------------------------------*/
    function init () {
      $el.each(function(){
        var $trigger = $(this);
        var $url = $trigger.data('tag');
    
        $trigger.on('click', function(e){
          e.preventDefault();
            location.href = $url;
        });
      });

    }

    /** ------------------------------
     *  Private
     ---------------------------------*/

    /** ------------------------------
     *  Public
     ---------------------------------*/

    // ---------- init and export ----------
    init();
    return self;
};

}(this, this.document, this.App));

