/**
 *  FlashMessage.js
 *
 *  @project      shareshima
 *  @since        2017/06/14
 *  @version      0.0.1
 *  @author       Tsuji
 */

(function(win, doc, App){

"use strict";

/**
 * フラッシュメッセージ
 *
 * @public
 * @param  {string} selector
 * @param  {object} options
 * @return  {App.Behaviors.FlashMessenger} self
 */
App.Behaviors.FlashMessenger = function (selector, options) {
    var self = new App.Services.Observer(),
        $el = $(selector),
        isModalVisible = false;

        self._util = new App.Services.Util();

    /** ------------------------------
     *  Initialize
     ---------------------------------*/

    function init() {
    }

    /** ------------------------------
     *  Private
     ---------------------------------*/
    /**
     *  showFlashMessageActually
     *
     *  @function　フラッシュメッセージを表示する
     *  @private
     *  @param {string} selector modal classを持つセレクター
     *  @param {string} message 表示内容、HTMLも可
     *  @param {string} type OPTIONAL success, danger, warningのいずれか
     *  @param {integer} duration OPTIONAL 表示時間
     *  @param {boolean} showBackground OPTIONAL 表示時間
     *  @return {void}
     */
    function showFlashMessageActually(selector, message, type, duration, showBackground) {
        duration = duration || 4500;
        showBackground = showBackground || false;
        var self = this,
            $modal = $(selector),
            $body = $('body'),
            timer,
            // @see http://foundation.zurb.com/sites/docs/reveal.html
            modalOptions = {
                'animationIn': 'fade-in',
                'animationOut': 'fade-out'
            };

        if (isModalVisible) {
            return;
        }
        isModalVisible = true;
        if (1 > $modal.length) {
            isModalVisible = false;
            return;
        }
        if (message) {
            $modal.find('.js-msg').html(message);
        }

        // セットされているtypeを先に削除する（サーバーからのフラッシュメッセージは対象外）
        if(selector === '#js-flashMessage') {
            $modal
                .removeClass('m-flash-message--danger')
                .removeClass('m-flash-message--info')
                .removeClass('m-flash-message--warning')
        }
        if (type) {
            type = type.charAt(0).toLowerCase() + type.slice(1);
            // typeに応じたスタイル用クラス付加
            $modal.addClass('m-flash-message--'+type);
        }

        // モーダルが閉じられたとき
        // @see http://foundation.zurb.com/sites/docs/reveal.html
        $modal.on('closed.zf.reveal', function () {
            isModalVisible = false;
            clearTimeout(timer);
          }
        );

        // 表示するフラッシュメッセージのタイプで背景色を消す
        if (!showBackground) {
            $modal
                .on('open.zf.reveal', function (e) {
                    $body.addClass('is-flashMessage');
                })
                .on('closed.zf.reveal', function (e) {
                    $body.removeClass('is-flashMessage');
                    isModalVisible = false;
                });
        }

        // オプションを設定したモーダルを作る
        var $modalInsctance = new Foundation.Reveal($modal, modalOptions);

        // モーダルを表示して消す
        $modalInsctance.open();
        timer = setTimeout(function() {
            $modalInsctance.close();
            isModalVisible = false;
        }, duration);
    }


    /** ------------------------------
     *  Public
     ---------------------------------*/
    /**
     *  flashMessage
     *
     *  @function　showFlashMessageActuallyのラッパー
     *  @public
     *  @param {mixed} params showFlashMessageActuallyの引数として渡すことが可能な各種オプション（文字列を渡すとそのまま表示）
     *  @return {void}
     */
    self.flashMessage = function(params) {
        if (typeof params === 'string') {
            showFlashMessageActually('#js-flashMessage', params);
        } else {
            showFlashMessageActually(
                params.selector || '#js-flashMessage',
                params.message,
                params.type,
                params.duration,
                params.showBackground
            );
        }
    };

    /**
     *  flashMessageSuccess
     *
     *  @function　showFlashMessageActuallyのラッパー
     *  @public
     *  @param {string} message
     *  @return {void}
     */
    self.flashMessageSuccess = function(message) {
        showFlashMessageActually('#js-flashMessage', message, 'success');
    };

    /**
     *  flashMessageInfo
     *
     *  @function　showFlashMessageActuallyのラッパー
     *  @public
     *  @param {string} message
     *  @return {void}
     */
    self.flashMessageInfo = function(message) {
        showFlashMessageActually('#js-flashMessage', message, 'info');
    };

    /**
     *  flashMessageWarning
     *
     *  @function　showFlashMessageActuallyのラッパー
     *  @public
     *  @param {string} message
     *  @return {void}
     */
    self.flashMessageWarning = function(message) {
        showFlashMessageActually('#js-flashMessage', message, 'warning');
    };

    /**
     *  flashMessageDanger
     *
     *  @function　showFlashMessageActuallyのラッパー
     *  @public
     *  @param {string} message
     *  @return {void}
     */
    self.flashMessageDanger = function(message) {
        showFlashMessageActually('#js-flashMessage', message, 'danger');
    };

    /**
     *  flashMessageByServer
     *
     *  @function　showFlashMessageActuallyのラッパー（サーバーからのFlashMessageを表示）
     *  @public
     *  @return {void}
     */
    self.flashMessageByServer = function() {
        showFlashMessageActually('#js-flashMessageByServer');
    };

    // ---------- init and export ----------
    init();
    return self;
};

}(this, this.document, this.App));

