/**
 *  SetAddress.js
 *
 *  @project      nagano
 *  @since        2019/12/04
 *  @version      0.0.1
 *  @author       Ishibashi
 */

(function(win, doc, App){

"use strict";

/**
 * カスタムセレクトボックス
 *
 * @public
 * @param  {string} selector
 * @return  {App.Behaviors.SetAddress} self
 */
App.Behaviors.SetAddress = function (selector) {
    var self = new App.Services.Observer(),
        $el = $(selector);
        
    self._util = new App.Services.Util();
    self._fm = new App.Behaviors.FlashMessenger();
      
    /** ------------------------------
     *  Initialize
     ---------------------------------*/

    function init() {
        setAddress();
    }

    /** ------------------------------
     *  Private
     ---------------------------------*/
    /**
     *  getWindowInfo
     *
     *  @function スクロール値と画面の高さ取得
     *  @private
     *  @return {void}
     */
     
      var setAddress = function() {
        var $address = $el;
        $address.each(function() {
          var $zip = $(this).find('.js-set-zip');
          var $prefecture = $(this).find('.js-set-prefecture');
          var $city = $(this).find('.js-set-city');
          var $wrap = $zip.closest(".js-validate-wrap");
          $zip.on('change', function() {
            var zipcode = $zip.val();
            if (!zipcode) { return; }
            // 整形
            zipcode = zipcode.replace(/[０-９]/g, function(s) {
              return String.fromCharCode(s.charCodeAt(0) - 0xFEE0);
            });
            zipcode = zipcode.replace(/[^0-9]/g, '');
            $zip.val(zipcode);
            // 郵便番号検索
            var type = 'GET',
                url = '/apiInternal/zipcloud',
                params = { "zipcode": zipcode },
                datatype = 'json';
            self._util
                .ajax(type, url, params, datatype)
                .done(function(res) {
                  // 住所設定
                  if (res.data[0]) {
                    if ($prefecture.length) {
                      var prefCode = "";
                      if (res.data[0].prefCode) {
                        prefCode = res.data[0].prefCode;
                        prefCode = Number(prefCode);
                      }
                      if (prefCode) {
                        $prefecture.val(prefCode);
                        $prefecture.change();
                        $prefecture.closest(".js-validate-wrap").removeClass("is-error");
                      }
                    }
                    if ($city.length) {
                      var city = "";
                      if (res.data[0].city) {
                        city = res.data[0].city;
                        if (res.data[0].town) {
                          city += res.data[0].town;
                        }
                      }
                      if (city) {
                        $city.val(city);
                        $city.next().addClass('active')
                        $city.closest(".js-validate-wrap").removeClass("is-error");
                      }
                    }
                    $zip.removeClass("is-zip-error");
                    $wrap.removeClass("is-error");
                  } else {
                    $zip.addClass("is-zip-error");
                    $wrap.addClass("is-error");
                    self._fm.flashMessageDanger('郵便番号を確認してください');
                  }
                })
                .fail(function(data) {
                  // error message
                  self._fm.flashMessageDanger('エラーが発生しました、時間を置いてお試し下さい、申し訳ありません');
                  $zip.addClass("is-zip-error");
                  $wrap.addClass("is-error");
                })
                .always(function() {
                });
          });
        });
      }

    /** ------------------------------
     *  Public
     ---------------------------------*/


    // ---------- init and export ----------
    init();
    return self;
};

}(this, this.document, this.App));

