/**
 *  DiscerningSearchEn.js
 *
 *  @project      nagano
 *  @since        2019/11/26
 *  @version      0.0.1
 *  @author       Hatsugai
 */

(function(win, doc, App){

"use strict";

/**
 * こだわり条件から探す
 *
 * @public
 * @param  {string} selector
 * @return  {App.Behaviors.DiscerningSearch} self
 */
App.Behaviors.DiscerningSearchEn = function (selector) {
    var self = new App.Services.Observer(),
        $el = $(selector),
        $accordionTarget = $el.find('.js-accordion-target'),
        $salesDateAccordionTarget = $el.find('.js-sales-date-accordion-target'),
        $areaTarget = $el.find('.js-discerning-area-target'),
        $salesDateTarget = $el.find('.js-discerning-sales-date-target'),
        $submitBtn = $el.find('.js-discerning-submit'),
        is_disabled = true;

    /** ------------------------------
     *  Initialize
     ---------------------------------*/

    function init() {
      //サブミットボタンお制御
      $('.js-input-valid, .js-validate-select, #promotion-video-url, .js-input-text-valid').on('change', function(){
          disabled();
      });

      function createOptionElm(data) {
        var option = document.createElement('option');
        option.innerText = data.text;
        option.value = data.value;
        return option;
      }

      setTimeout(function() {
        $el.find('.mdb-select').materialSelect();
      }, 10);

      // アコーディオン処理
      $el.find('.js-accordion-content').slideUp(0);
      $accordionTarget.on('change', function() {
        var $this = $(this);
        var $content = $(this).parent().next();
        if ($content.hasClass('js-accordion-content')) {
          setTimeout(function() {
            
            if ($this.prop('checked')) {
                $content.find('#best-before-date').prop('disabled',false);
                $content.slideDown();
            } else {
                $content.find('#best-before-date').prop('disabled',true);
                $content.find('#best-before-date').val('');
                $content.slideUp();
                disabled();
            }
          }, 10);
        }
      });

      //送信
      $submitBtn.on('click', function(e) {
        e.preventDefault();
        if($(this).hasClass('is-disabled')) {
          return;
        }
        var link = createLink();
        if (link) {
          location.href = link;
        }
      });
    }
    
    function disabled() {
        var valid = false;
        $('.js-validate-select').each(function(){
            if($(this).val()) {
                valid = true;
                $submitBtn.removeClass('is-disabled');
            }
        });
        if($('.js-input-text-valid').val()) {
            valid = true;
            $submitBtn.removeClass('is-disabled');
        }
  
        if($('#promotion-video-url').prop('checked') == true) {
            valid = true;
            $submitBtn.removeClass('is-disabled');
        }
        if(!valid) {
            $submitBtn.addClass('is-disabled');
        }
    }

    function createLink() {
      var params = '';
      // 親要素のみ
      $('.js-validate-select').each(function(){
          if($(this).val()) {
              var param = $(this).attr('name') +'='+ $(this).val();
              if(params.length) {
                params = params + '&' + param;
              } else {
                params = param;
              }
          }
      });
      if($('.js-input-text-valid').val()) {  
          var param = $('.js-input-text-valid').attr('name') +'='+ $('.js-input-text-valid').val();
          
          if(params.length) {
            params = params +'&' + param;
          } else {
            params = param;
          }
      }
      
      if($('#promotion-video-url').prop('checked') == true) {
          var param = $('#promotion-video-url').attr('name') +'='+ $('#promotion-video-url').val();
          if(params.length) {
            params = params +'&' + param;
          } else {
            params = param;
          }
      }

      if (params.length) {
          if ($('body').hasClass('is-ch')) {
              return "/ch/foods?" + params;
          } else {
              return "/en/foods?" + params;
          }
      }

      return false;
    }

    // ---------- init and export ----------
    init();
    return self;
};

}(this, this.document, this.App));
