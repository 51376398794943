/**
 *  Favorite.js
 *
 *  @project      shareshima
 *  @since        2016/06/12
 *  @version      0.0.1
 *  @author       Tsuji
 */

(function(win, doc, App){

"use strict";

/**
 * お気に入り登録
 *
 * @public
 * @return {App.Behaviors.Favorite} self
 */
App.Behaviors.Favorite = function (selector) {
    var self = new App.Services.Observer(),
        $el = $(selector),
        food_id,
        is_favorite = false,
        is_apiCall = false;
        
    self._util = new App.Services.Util();
    self._fm = new App.Behaviors.FlashMessenger();

    /** ------------------------------
     *  Initialize
     ---------------------------------*/
    function init () {
      
      if ($('.js-clipped-food').length > 0) {
        is_favorite = true;
      }
      
      //お気に入りボタンクリック時
      $el.on('click',function(e){
        e.preventDefault();
        if (is_apiCall) { return; }
        is_apiCall = true;
        food_id = $(this).data('food');
        
        if ($(this).hasClass('is-active')) {
            clipDelete($(this));
        } else {
            clip($(this));
        }
        
      });

    }

    /** ------------------------------
     *  Private
     ---------------------------------*/
    /**
     *  clip
     *
     *  @function　お気に入り登録
     *  @public
     *  @param {string} $favorite お気に入りボタン
     *  @return {void}
     */
      var clip = function($favorite) {
        $.ajax({
          type: "GET",
          dataType: "json",
          url : '/apiInternal/clip/'+food_id,
        })
        .done(function(res){
          if (res.status === 0) {
              $favorite.addClass('is-active is-animation');
              if (is_favorite) {
                $favorite.html('お気に入りを解除<span class="fas fa-times"></span>');
              }
          }
          
          is_apiCall = false;
        });
      }

    /**
     *  clipDelete
     *
     *  @function　お気に入り登録解除
     *  @public
     *  @param {string} $favorite お気に入りボタン
     *  @return {void}
     */
      var clipDelete = function($favorite) {
        $.ajax({
          type: "GET",
          dataType: "json",
          url : '/apiInternal/clipDelete/'+food_id,
        })
        .done(function(res){
          if (res.status === 0) {
              $favorite.removeClass('is-active is-animation');

              if (is_favorite) {
                $favorite.html('<span class="fas fa-heart"></span><span class="far fa-heart"></span>お気に入り');
              }
          }
          
          is_apiCall = false;
        });
      }
      
    /** ------------------------------
     *  Public
     ---------------------------------*/
     
    // ---------- init and export ----------
    init();
    return self;
};

}(this, this.document, this.App));
