/**
 *  Tab.js
 *
 *  @project      shareshima
 *  @since        2017/12/26
 *  @version      0.0.2
 *  @author       Tsuji.Ishibashi
 */

(function(win, doc, App){

"use strict";

/**
 * タブメニュー切り替え
 *
 * @public
 * @param {string} selector
 * @return {App.Behaviors.Tabl}
 */
App.Behaviors.NaganoMallMenuPc = function (selector) {
    var self = new App.Services.Observer(),
        $el = $(selector);

    /** ------------------------------
     *  Initialize
     ---------------------------------*/
    function init () {
      var $trigger = $el.find('.js-sub-category-trigger');
      $trigger.on('mouseenter' ,function(e){
          var pos = $(this).position().top;
          var sc = $('.js-modal-menu').find('.modal-menu__contents').scrollTop();
          
          $(this).find('.js-tooltip-subcat').css({'top': pos + sc + 60});
      });
/*
      var $trigger = $el.find('.js-category-trigger'),
          $subCategory = $('.js-sub-category');
          
      $('.js-category-trigger').on('mouseenter' ,function(e){
          e.preventDefault();
          $('.js-category-trigger').removeClass('is-active');
          $subCategory.find('> li').hide();
          var cat = $(this).data('cat');
          $(this).addClass('is-active');
          
          if (cat == 1) {
            $subCategory.find('.js-cat1').fadeIn();
            $subCategory.find('.js-cat2').fadeIn();
          } else if (cat >= 2) {
            $subCategory.find('.js-cat'+ (cat + 1)).fadeIn();
          }
      });
*/
    }

    /** ------------------------------
     *  Private
     ---------------------------------*/

    /** ------------------------------
     *  Public
     ---------------------------------*/

    // ---------- init and export ----------
    init();
    return self;
};

}(this, this.document, this.App));

