/**
 *  ModalResetPassword.js
 *
 *  @project      shareshima
 *  @since        2017/12/21
 *  @version      0.0.1
 *  @author       Tsuji
 */

(function(win, doc, App){

"use strict";

/**
 * パスワードリセットモーダル
 *
 * @public
 * @param  {string} selector
 * @return  {App.Behaviors.ModalResetPassword} self
 */
App.Behaviors.ModalResetPassword = function (selector) {
    var self = new App.Services.Observer(),
        $el = $(selector),
        $email = $el.find('#reset-password-mail'),
        is_callApi = false;

        self._util = new App.Services.Util();
        self._validate = new App.Behaviors.Validator;
        self._fm = new App.Behaviors.FlashMessenger();
    /** ------------------------------
     *  Initialize
     ---------------------------------*/

    function init() {
        if($('body').hasClass('is-en')){
            $email.on('change', function(){
                if ($email.val()) {
                    $el.find('.js-reset-password-trigger').removeClass('is-disabled');
                } else {
                    $el.find('.js-reset-password-trigger').addClass('is-disabled');
                }
            });
          
            $el.find('.js-reset-password-trigger').on('click', function(e){
              e.preventDefault();
              if($(this).hasClass('is-disabled')) { return; }
              if (is_callApi) { return; }
              is_callApi = true;
              
              resetApiCall();
            });
        }else {
            $el.find('.js-reset-password-trigger').on('click', function(e){
              e.preventDefault();
              if (is_callApi) { return; }
              is_callApi = true;
              
              resetApiCall();
            }); 
        }
    }

    /** ------------------------------
     *  Private
     ---------------------------------*/
    /**
     *  resetApiCall
     *
     *  @function　apiの呼び出し
     *  @public
     *  @return {void}
     */
      var resetApiCall = function() {
        var valid = false,
            msg,
            type = 'POST',
            url = '/apiInternal/sendPassResetUrl',
            params = {
              "email" : $email.val(),
            },
            datatype = 'json';
        // APIコール
        self._util.ajax(type, url, params, datatype).done(function(res) {
            valid = (res.status === 0) ? true : false;
            msg = res.message;
            sendActionMsg(msg, valid);
            
            if(!valid){
                self._fm.flashMessage('入力に誤りがあります');
                is_callApi = false;
            }
            if(valid){
                setTimeout(function(){
                    $el.fadeOut(500, function() {
                        $email.val('');
                        $el.find('.js-msg-block').hide();
                        is_callApi = false;
                    });
                }, 2000);
            }
            
        }).fail(function(res) {
            sendActionMsg('データの取得に失敗しました。');
            is_callApi = false;
        });
      }
    /**
     * sendActionMsg
     *
     * @function 通信結果のメッセージ
     * @private
     * @param {string} msg
     * @param {boolean} valid
     * @return {void}
     */
     function sendActionMsg(msg, valid) {
        var $msgBlock = $el.find('.js-msg-block');
            $msgBlock.hide().removeClass('is-error').empty().text(msg).fadeIn();
        if(!valid) {
            $msgBlock.addClass('is-error');
        }
    }
      
    /** ------------------------------
     *  Public
     ---------------------------------*/


    // ---------- init and export ----------
    init();
    return self;
};

}(this, this.document, this.App));

