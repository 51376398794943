/**
 *  route.js
 *
 *  @project      shareshima
 *  @since        2015/07/14
 *  @version      0.0.1
 *  @author       K.T.T (Kodama.Tsuji.Tamura)
 *  @fileOverview K.T.TフレームワークのURL・URLリストからApp.Modules.Pageクラスをマッピングする
 */

(function(win, doc){

"use strict";

/**
 *  jQueryの初期化ハンドラ
 *  ページ別に、Page以下のハンドラを実行する
 */
$(function(){
    // ルール1: コンストラクタ引数のrulesのpathnameとPageClassの対応表(pattern)がマッチする場合、そのPageClassを初期化
    // ルール2: /controller名/action名のURLの場合、App.Modules.[Controller名].[Action名]Pageのクラスを自動で初期化
    // ルール3: ルール1、ルール2でマッチしない場合、コンストラクタ引数のnot_matchのPageClassを初期化する
    // ※jsファイル名、クラス名はキャメルケースで
    if($('body').hasClass('is-nagano-mall')) {
        var loader = new win.App.Services.ModuleLoader({
            rules : [{
                note    : 'プロフィール',
                page    : 'Profile',
                pattern : [
                    /nagano-mall(\/)profile(\/)[0-9]+/
                ]
            },{
                note    : 'マイページ商品一覧',
                page    : 'NaganoMallBusinessMy',
                pattern : [
                    /nagano-mall(\/)ecSites(\/)my/
                ]
            },{
                note    : 'ECサイト一覧',
                page    : 'NaganoMallBusiness',
                pattern : [
                    /nagano-mall(\/)ecSites/
                ]
            },{
                note    : '長野モール販売サイト登録',
                page    : 'NaganoMallEcSites',
                pattern : [
                    /\/nagano-mall(\/)addEcSites/
                ]
            },{
                note    : '長野モール販売サイト編集',
                page    : 'NaganoMallEcSites',
                pattern : [
                    /nagano-mall(\/)editEcSites(\/)[0-9]+/
                ]
            },{
                note    : '長野モールキャンペーン参加申し込み',
                page    : 'NaganoMallCampaign',
                pattern : [
                    /\/nagano-mall(\/)campaignEntry/
                ]
            // },{
            //     note    : 'テイクアウト・デリバリー',
            //     page    : 'TakeoutDelivery',
            //     pattern : [
            //         /nagano-mall(\/)takeoutDelivery/
            //     ]
            },{
                note    : '商品編集',
                page    : 'AddProduct',
                pattern : [
                    /nagano-mall(\/)editProduct(\/)[0-9]+/
                ]
            },{
                note    : '商品編集完了',
                page    : 'Other',
                pattern : [
                    /nagano-mall(\/)editProduct(\/)complete/
                ]
            },{
                note    : '商品登録完了',
                page    : 'Other',
                pattern : [
                    /nagano-mall(\/)addProduct(\/)complete/
                ]
            },{
                note    : '商品登録',
                page    : 'AddProduct',
                pattern : [
                    /nagano-mall(\/)addProduct/
                ]
            },{
                note    : 'マイページ商品一覧',
                page    : 'NaganoMallProductsMy',
                pattern : [
                    /nagano-mall(\/)products(\/)my/
                ]
            },{
                note    : '商品一覧',
                page    : 'Product',
                pattern : [
                    /nagano-mall(\/)products/
                ]
            },{
                note    : '売りて登録',
                page    : 'NaganoMallAddSupplier',
                pattern : [
                    /nagano-mall(\/)addSupplier/
                ]
            },{
                note    : '問い合わせ',
                page    : 'NaganoMallContacts',
                pattern : [
                    /nagano-mall(\/)contacts/
                ]
            },{
                note    : 'メッセージ一覧',
                page    : 'Other',
                pattern : [
                    /nagano-mall(\/)messages/
                ]
            },{
                note    : '売り手の方へ',
                page    : 'NaganoMallGuide',
                pattern : [
                    /nagano-mall(\/)guideForSuppliers/
                ]
            },{
                note    : 'オールNAGANOモールとは',
                page    : 'Other',
                pattern : [
                    /nagano-mall(\/)about/
                ]
            },{
                note    : 'ご利用にあたっての注意点',
                page    : 'Other',
                pattern : [
                    /nagano-mall(\/)terms-of-service/
                ]
            },{
                note    : 'プライバシーポリシー',
                page    : 'Other',
                pattern : [
                    /nagano-mall(\/)privacy/
                ]
            },{
                note    : 'プロフィール',
                page    : 'Other',
                pattern : [
                    /nagano-mall(\/)myProfile/
                ]
            },{
                note    : 'ブログプレビュー',
                page    : 'BlogArticle',
                pattern : [
                    /\/nagano-mall(\/)preview/
                ]
            },
            {
                note    : 'ブログ検索結果',
                page    : 'BlogSearch',
                pattern : [
                    /\/nagano-mall(\/)search/
                ]
            },
            {
                note    : 'ブログ記事',
                page    : 'BlogArticle',
                pattern : [
                    /\/nagano-mall(\/)[0-9]+/
                ]
            },{
                note    : 'naganoモールblog',
                page    : 'Blog',
                pattern : [
                    /\/nagano-mall(\/)blog/
                ]
            },{
                note    : '長野モールTOP',
                page    : 'NaganoMallTop',
                pattern : [
                    /\/nagano-mall/
                ]
            }],
            not_match : 'Other'
        });
    } else if($('body').hasClass('is-kitchencar')) {
        var loader = new win.App.Services.ModuleLoader({
            rules : [{
                note    : '販売マッチング 出品者一覧',
                page    : 'KitchencarExhibitors',
                pattern : [
                    /kitchencar(\/)exhibitors/
                ]
            },{
                note    : '販売マッチング販売手法登録一覧',
                page    : 'KitchencarMy',
                pattern : [
                    /kitchencar(\/)salesMethod(\/)my/
                ]
            },{
                note    : '販売マッチング販売手法編集',
                page    : 'KitchencarSalesMethodEdit',
                pattern : [
                    /\/editSalesMethod/
                ]
            },{
                note    : '販売マッチング販売手法詳細',
                page    : 'KitchencarSalesMethod',
                pattern : [
                    /kitchencar(\/)salesMethod(\/)[0-9]+/
                ]
            },{
                note    : '販売マッチング販売手法一覧',
                page    : 'KitchencarSalesMethod',
                pattern : [
                    /kitchencar(\/)salesMethod/
                ]
            },{
                note    : '販売マッチング販売機会登録一覧',
                page    : 'KitchencarEventsMy',
                pattern : [
                    /kitchencar(\/)events(\/)my/
                ]
            },{
                note    : '販売マッチング販売機会詳細',
                page    : 'KitchencarEvents',
                pattern : [
                    /kitchencar(\/)events(\/)[0-9]+/
                ]
            },{
                note    : '販売マッチング販売機会一覧',
                page    : 'KitchencarEvents',
                pattern : [
                    /kitchencar(\/)events/
                ]
            },{
                note    : '販売マッチング ご利用ガイド 出展者',
                page    : 'KitchencarGuide',
                pattern : [
                    /kitchencar(\/)guideForOrganizer/
                ]
            },{
                note    : '販売マッチング ご利用ガイド 出展者',
                page    : 'KitchencarGuide',
                pattern : [
                    /kitchencar(\/)guideForExhibitor/
                ]
            },{
                note    : '販売マッチング 手法提供者申請',
                page    : 'KitchencarUserAddEdit',
                pattern : [
                    /kitchencar(\/)addExhibitor/
                ]
            },{
                note    : '販売マッチング 機会提供者申請',
                page    : 'KitchencarUserAddEdit',
                pattern : [
                    /kitchencar(\/)addOrganizer/
                ]
            },{
                note    : '販売手法申請完了',
                page    : 'Other',
                pattern : [
                    /kitchencar(\/)addSalesMethod(\/)complete/
                ]
            },{
                note    : '販売マッチング 販売手法登録',
                page    : 'KitchencarAddEdit',
                pattern : [
                    /kitchencar(\/)addSalesMethod/
                ]
            },{
                note    : '販売マッチング 販売機会登録',
                page    : 'Other',
                pattern : [
                    /kitchencar(\/)addEvent(\/)complete/
                ]
            },{
                note    : '販売マッチング 販売機会登録',
                page    : 'KitchencarAddEditEvent',
                pattern : [
                    /kitchencar(\/)addEvent/
                ]
            },{
                note    : '販売マッチング 販売機会編集',
                page    : 'KitchencarEventsEdit',
                pattern : [
                    /kitchencar(\/)editEvent/
                ]
            },{
                note    : '販売マッチング 問い合わせ',
                page    : 'KitchencarContacts',
                pattern : [
                    /kitchencar(\/)contacts/
                ]
            },{
                note    : 'プライバシーポリシー',
                page    : 'Other',
                pattern : [
                    /kitchencar(\/)privacy/
                ]
            },{
                note    : '利用規約',
                page    : 'Other',
                pattern : [
                    /kitchencar(\/)terms-of-service/
                ]
            },{
                note    : 'マイプロフィール',
                page    : 'Other',
                pattern : [
                    /kitchencar(\/)myProfile/
                ]
            },{
                note    : '販売マッチングTOP',
                page    : 'KitchencarTop',
                pattern : [
                    /kitchencar/
                ]
            }],
            not_match : 'Other'
        });
    } else {
        var loader = new win.App.Services.ModuleLoader({
            rules : [{
                note    : 'サンプル画面',
                page    : 'Sample',
                pattern : [
                    /\/samples\/.*$/
                ]
            },
            {
                note    : 'ティザー',
                page    : 'Teaser',
                pattern : [
                    /^(\/)?$/
                ]
            },
            {
                note    : '英語 ガイド',
                page    : 'Other',
                pattern : [
                    /\/en(\/)guideForBuyers/
                ]
            },
            {
                note    : '簡体語 ガイド',
                page    : 'Other',
                pattern : [
                    /\/ch(\/)guideForBuyers/
                ]
            },
            {
                note    : '英語トップ',
                page    : 'TopG',
                pattern : [
                    /\/en(\/)top/
                ]
            },
            {
                note    : '簡体トップ',
                page    : 'TopG',
                pattern : [
                    /\/ch(\/)top/
                ]
            },
            {
                note    : 'トップ',
                page    : 'Top',
                pattern : [
                    /\/top/
                ]
            },
            {
                note    : 'お気に入り登録一覧',
                page    : 'ClippedFoods',
                pattern : [
                    /\/clippedFoods/
                ]
            },
            {
                note    : '食品登録',
                page    : 'FoodsAdd',
                pattern : [
                    /\/foods(\/)add/
                ]
            },
            {
                note    : '食品登録一覧',
                page    : 'FoodsMy',
                pattern : [
                    /\/foods(\/)my/
                ]
            },
            {
                note    : '食品詳細 英語',
                page    : 'FoodsViewG',
                pattern : [
                    /en(\/)foods(\/)[0-9]+/
                ]
            },
            {
                note    : '食品詳細 簡体',
                page    : 'FoodsViewG',
                pattern : [
                    /ch(\/)foods(\/)[0-9]+/
                ]
            },
            {
                note    : '食品詳細',
                page    : 'FoodsView',
                pattern : [
                    /\/foods(\/)[0-9]+/
                ]
            },
            {
                note    : '食品お問い合わせ',
                page    : 'FoodsContact',
                pattern : [
                    /\/foods(\/)contact(\/)[0-9]+/
                ]
            },
            {
                note    : '食品一覧',
                page    : 'Foods',
                pattern : [
                    /\/foods/
                ]
            },
            {
                note    : '広告出稿ユーザー商品一覧',
                page    : 'AdFoods',
                pattern : [
                    /\/adFoods(\/)[0-9]+/
                ]
            },
            {
                note    : 'ご利用の流れ',
                page    : 'Flow',
                pattern : [
                    /\/flow/
                ]
            },
            {
                note    : 'プロフィール編集',
                page    : 'MyProfile',
                pattern : [
                    /\/myProfile/
                ]
            },
            {
                note    : 'プロフィール登録画面',
                page    : 'Profile',
                pattern : [
                    /\/profile/
                ]
            },
            {
                note    : '住所登録',
                page    : 'AddAddress',
                pattern : [
                    /\/addAddress/
                ]
            },
            {
                note    : '住所編集画面',
                page    : 'EditAddress',
                pattern : [
                    /\/editAddress/
                ]
            },
            {
                note    : 'よくある質問',
                page    : 'Faqs',
                pattern : [
                    /\/faqs/
                ]
            },
            {
                note    : 'お問い合わせ',
                page    : 'Contacts',
                pattern : [
                    /\/contacts/
                ]
            },
            {
                note    : 'サポートお問い合わせ',
                page    : 'Supports',
                pattern : [
                    /\/supports/
                ]
            },
            {
                note    : '売りたい',
                page    : 'Howto',
                pattern : [
                    /\/howtoSell/
                ]
            },
            {
                note    : '買いたい',
                page    : 'Howto',
                pattern : [
                    /\/howtoBuy/
                ]
            },
            {
                note    : '退会',
                page    : 'Resign',
                pattern : [
                    /\/resign/
                ]
            },
            {
                note    : 'ユーザーへのメッセージ',
                page    : 'UsersContact',
                pattern : [
                    /\/users(\/)contact(\/)[0-9]+/
                ]
            },
            {
                note    : '買い手登録',
                page    : 'UsersAdd',
                pattern : [
                    /\/addBuyer/
                ]
            },
            {
                note    : '売り手登録 英語 完了',
                page    : 'Other',
                pattern : [
                    /\/en(\/)addSupplier(\/)complete/
                ]
            },
            {
                note    : '売り手登録 英語',
                page    : 'UsersAddE',
                pattern : [
                    /\/en(\/)addSupplier/
                ]
            },
            {
                note    : '売り手登録 簡体 完了',
                page    : 'Other',
                pattern : [
                    /\/ch(\/)addSupplier(\/)complete/
                ]
            },
            {
                note    : '売り手登録 簡体',
                page    : 'UsersAddC',
                pattern : [
                    /\/ch(\/)addSupplier/
                ]
            },
            {
                note    : '売り手登録',
                page    : 'UsersAdd',
                pattern : [
                    /\/addSupplier/
                ]
            },
            {
                note    : '非食品 英語完了',
                page    : 'Other',
                pattern : [
                    /\/en(\/)addNonFood(\/)complete/
                ]
            },
            {
                note    : '非食品 簡体完了',
                page    : 'Other',
                pattern : [
                    /\/ch(\/)addNonFood(\/)complete/
                ]
            },
            {
                note    : '非食品 英語',
                page    : 'FoodsAddG',
                pattern : [
                    /\/en(\/)addNonFood/
                ]
            },
            {
                note    : '非食品 簡体',
                page    : 'FoodsAddG',
                pattern : [
                    /\/ch(\/)addNonFood/
                ]
            },
            {
                note    : '食品 簡体完了',
                page    : 'Other',
                pattern : [
                    /\/ch(\/)addFood(\/)complete/
                ]
            },
            {
                note    : '食品 英語完了',
                page    : 'Other',
                pattern : [
                    /\/en(\/)addFood(\/)complete/
                ]
            },
            {
                note    : '食品 英語',
                page    : 'FoodsAddG',
                pattern : [
                    /\/en(\/)addFood/
                ]
            },
            {
                note    : '食品 簡体',
                page    : 'FoodsAddG',
                pattern : [
                    /\/ch(\/)addFood/
                ]
            },
            {
                note    : '食品',
                page    : 'FoodsAdd',
                pattern : [
                    /\/addFood/
                ]
            },
            {
                note    : '非食品',
                page    : 'FoodsAdd',
                pattern : [
                    /\/addNonFood/
                ]
            },
            {
                note    : '売り手一覧',
                page    : 'UsersSupplier',
                pattern : [
                    /\/suppliers/
                ]
            },
            {
                note    : '買い手一覧',
                page    : 'UsersBuyer',
                pattern : [
                    /\/buyers/
                ]
            },
            {
                note    : '食品編集 英語',
                page    : 'FoodsAddG',
                pattern : [
                    /\/en(\/)editFood/
                ]
            },
            {
                note    : '非食品編集 英語',
                page    : 'FoodsAddG',
                pattern : [
                    /\/en(\/)editNonFood/
                ]
            },
            {
                note    : '非食品編集 簡体',
                page    : 'FoodsAddG',
                pattern : [
                    /\/ch(\/)editNonFood/
                ]
            },
            {
                note    : '食品編集 簡体',
                page    : 'FoodsAddG',
                pattern : [
                    /\/ch(\/)editFood/
                ]
            },
            {
                note    : '食品編集',
                page    : 'FoodsEdit',
                pattern : [
                    /\/editFood/
                ]
            },
            {
                note    : '非食品編集',
                page    : 'FoodsEdit',
                pattern : [
                    /\/editNonFood/
                ]
            },
            {
                note    : 'ブログプレビュー',
                page    : 'BlogArticle',
                pattern : [
                    /\/blog(\/)preview/
                ]
            },
            {
                note    : 'ブログ検索結果',
                page    : 'BlogSearch',
                pattern : [
                    /\/blog(\/)search/
                ]
            },
            {
                note    : 'ブログ記事',
                page    : 'BlogArticle',
                pattern : [
                    /\/blog(\/)[0-9]+/
                ]
            },
            {
                note    : 'ブログTOP',
                page    : 'Blog',
                pattern : [
                    /\/blog/
                ]
            },
            {
                note    : '展覧会英語',
                page    : 'ExhibitionsView',
                pattern : [
                    /\/en(\/)exhibitions(\/)[0-9]+/
                ]
            },
            {
                note    : '展覧会 簡体',
                page    : 'ExhibitionsView',
                pattern : [
                    /\/ch(\/)exhibitions(\/)[0-9]+/
                ]
            },
            {
                note    : '展覧会編集英語 完了',
                page    : 'Other',
                pattern : [
                    /\/en(\/)editExhibition(\/)complete/
                ]
            },
            {
                note    : '展覧会編集英語',
                page    : 'ExhibitionsAdd',
                pattern : [
                    /\/en(\/)editExhibition/
                ]
            },
            {
                note    : '展覧会編集 簡体 完了',
                page    : 'Other',
                pattern : [
                    /\/ch(\/)editExhibition(\/)complete/
                ]
            },
            {
                note    : '展覧会編集 簡体',
                page    : 'ExhibitionsAdd',
                pattern : [
                    /\/ch(\/)editExhibition/
                ]
            },
            {
                note    : '展覧会登録英語 完了',
                page    : 'Other',
                pattern : [
                    /\/en(\/)addExhibition(\/)complete/
                ]
            },
            {
                note    : '展覧会登録英語',
                page    : 'ExhibitionsAdd',
                pattern : [
                    /\/en(\/)addExhibition/
                ]
            },
            {
                note    : '展覧会登録 簡体 完了',
                page    : 'Other',
                pattern : [
                    /\/ch(\/)addExhibition(\/)complete/
                ]
            },
            {
                note    : '展覧会登録 簡体',
                page    : 'ExhibitionsAdd',
                pattern : [
                    /\/ch(\/)addExhibition/
                ]
            },
            {
                note    : 'マイページ',
                page    : 'Mypage',
                pattern : [
                    /\/mypage/
                ]
            },
            {
                note    : 'ティザー英語',
                page    : 'Teaser',
                pattern : [
                    /\/en(\/)about/
                ]
            },
            {
                note    : 'ティザー簡体',
                page    : 'Teaser',
                pattern : [
                    /\/ch(\/)about/
                ]
            },
            {
                note    : '簡体 プライバシー',
                page    : 'Other',
                pattern : [
                    /\/ch(\/)privacy/
                ]
            },
            {
                note    : '英語 プライバシー',
                page    : 'Other',
                pattern : [
                    /\/en(\/)privacy/
                ]
            },
            {
                note    : '簡体 利用規約',
                page    : 'Other',
                pattern : [
                    /\/ch(\/)terms-of-service/
                ]
            },
            {
                note    : '英語 利用規約',
                page    : 'Other',
                pattern : [
                    /\/en(\/)terms-of-service/
                ]
            },
            {
                note    : '英語 輸入実績',
                page    : 'Region',
                pattern : [
                    /\/ch(\/)region/
                ]
            },
            {
                note    : '英語 輸入実績',
                page    : 'Region',
                pattern : [
                    /\/en(\/)region/
                ]
            },
            {
                note    : '記事',
                page    : 'Other',
                pattern : [
                    /\/ch(\/)article(\/)[0-9]+/
                ]
            },
            {
                note    : '記事',
                page    : 'Other',
                pattern : [
                    /\/en(\/)article(\/)[0-9]+/
                ]
            },
            {
                note    : 'オーダー完了',
                page    : 'Other',
                pattern : [
                    /\/ch(\/)orderedFoods(\/)complete/
                ]
            },
            {
                note    : 'オーダー完了',
                page    : 'Other',
                pattern : [
                    /\/en(\/)orderedFoods(\/)complete/
                ]
            },
            {
                note    : '英語トップ',
                page    : 'TopG',
                pattern : [
                    /\/en/
                ]
            },
            {
                note    : '展示会マイページ',
                page    : 'ExhibitionsMy',
                pattern : [
                    /\/overseasExhibitions(\/)my/

                ]
            },
            {
                note    : '簡体トップ',
                page    : 'TopG',
                pattern : [
                    /\/ch/
                ]
            },
            {
                note    : '商談管理機能及び営業活動支援機能',
                page    : 'Progresses',
                pattern : [
                    /\/businessMeetings(\/)progresses/
                ]
            }],
            not_match : 'Other'
        });
    }
    loader.load();
});

}(this, this.document));
