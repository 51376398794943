/**
 *  SmoothScroll.js
 *
 *  @project      nagano
 *  @since        2019/11/06
 *  @version      0.0.1
 *  @author       Ishibashi
 */

(function(win, doc, App){

"use strict";

/**
 * ページトップ スクロール
 *
 * @public
 * @param  {string} selector
 * @return  void
 */
App.Behaviors.SmoothScroll = function () {
    var self = new App.Services.Observer();
    self._util = new App.Services.Util();
      
    /** ------------------------------
     *  Initialize
     ---------------------------------*/

    function init() {
      $('.js-smooth-scroll').on('click', function(e){ 
        e.preventDefault();
        var speed = 500; 
        var href= $(this).attr("href"); 
        var target = href == "#" || href == "" ? 'html' : href; 
        
        var position = $(target).offset().top; 
        $("html, body").animate({scrollTop:position}, speed, "swing"); 
        return false; 
      });
    }

    /** ------------------------------
     *  Private
     ---------------------------------*/


    /** ------------------------------
     *  Public
     ---------------------------------*/


    // ---------- init and export ----------
    init();
    return self;
};

}(this, this.document, this.App));

