/**
 *  FixedSideMenu.js
 *
 *  @project      nagano
 *  @since        2019/12/9
 *  @version      0.0.1
 *  @author       Ishibashi
 */

(function(win, doc, App){

"use strict";

/**
 *  サイドメニュー追従の処理
 *
 * @public
 * @return {App.Behaviors.FixedSideMenu}
 */
App.Behaviors.FixedSideMenu = function (more) {
    var self = new App.Services.Observer(),
        $win = $(window),
        headH = $('.js-header').outerHeight(),
        $contents = $('.js-contents-wrap'),
        $nav = $('.js-side-nav-contents'),
        navH = $('.js-fixed-side-menu').outerHeight(),
        mainH = $('.js-fixed-side-menu-contents').outerHeight(),
        pos;

    /** ------------------------------
     *  Initialize
     ---------------------------------*/
    function init () {
        if (window.innerWidth > 767) {
            if (more) {
              navH = $('.js-fixed-side-menu').outerHeight(),
              mainH = $('.js-fixed-side-menu-contents').outerHeight();              
              if (mainH > navH) {
                  pos = $win.scrollTop();                  
                  if (headH + navH - $(window).height() < pos ) {
                    $contents.addClass('is-fixed');
                    
                    if (headH + $contents.outerHeight() - $(window).height() < pos) {
                      $contents.addClass('is-bottom');
                    } else {
                      $contents.removeClass('is-bottom');
                    }
                  } else {
                    $contents.removeClass('is-fixed');
                  }
              }
            } else {
                if (mainH > navH) {
                  $win.on('scroll', function(){
                      pos = $(this).scrollTop();
                      if (headH + navH - $(window).height() < pos ) {
                        $contents.addClass('is-fixed');
                        
                        if (headH + $contents.outerHeight() - $(window).height() < pos) {
                          $contents.addClass('is-bottom');
                        } else {
                          $contents.removeClass('is-bottom');
                        }
                      } else {
                        $contents.removeClass('is-fixed');
                      }
                  });
                }
            }
        }
    }

    /** ------------------------------
     *  Private
     ---------------------------------*/

    /** ------------------------------
     *  Public
     ---------------------------------*/

    // ---------- init and export ----------
    init();
    return self;
};

}(this, this.document, this.App));

