/**
 *  Carousel.js
 *
 *  @project      shareshima
 *  @since        2015/07/31
 *  @version      0.0.1
 *  @author       K.T.T (Kodama.Tsuji.Tamura)
 */

(function(win, doc, App){

"use strict";

/**
 * 食品のカルーセル設置
 *
 * @public
 * @requires slick.js
 * @param {string} selector
 * @param {obj} op
 * @return {App.Behaviors.Carousel}
 */
App.Behaviors.Carousel = function (selector, op) {
    var self = new App.Services.Observer(),
        $el = $(selector);
        
        self._util = new App.Services.Util();

    /** ------------------------------
     *  Initialize
     ---------------------------------*/
    function init () {
      if (!op) {
        op = {
          infinite: true,
          speed: 300,
          slidesToShow: 1,
          variableWidth: true,
          useCSS: false,
        }
      }
      $el.slick(op);
    }
     

    /** ------------------------------
     *  Public
     ---------------------------------*/

    // ---------- init and export ----------
    init();
    return self;
};

}(this, this.document, this.App));

