/**
 *  AddressSearch.js
 *
 *  @project      shareshima
 *  @since        2019/08/05
 *  @version      0.0.1
 *  @author       ISHIBASHI
 */

(function(win, doc, App){

"use strict";

/**
 * タブレット・SP表示時のプロフィールアイコン表示処理
 *
 * @public
 * @return  {App.Behaviors.ProfileBalloon} self
 */
App.Behaviors.ProfileBalloon = function () {
    var self = new App.Services.Observer(),
        $el = $('.js-icon-profile');
        self._util = new App.Services.Util();

    /** ------------------------------
     *  Initialize
     ---------------------------------*/

    function init() {
        if (self._util.getuserAgent().SP_TB) {
            $el.each(function(){
                var $this = $(this),
                    balloonTimer;
                $this.on('click', function(){
                  clearTimeout(balloonTimer);
                  $this.addClass('is-show');
                  balloonTimer = setTimeout(function(){
                      $this.removeClass('is-show');
                  }, 3000);
                });
            });
        }
    }

    /** ------------------------------
     *  Private
     ---------------------------------*/
      
    /** ------------------------------
     *  Public
     ---------------------------------*/


    // ---------- init and export ----------
    init();
    return self;
};

}(this, this.document, this.App));

