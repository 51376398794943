/**
 *  AnchorScroll.js
 *
 *  @project      shareshima
 *  @since        2017/12/21
 *  @version      0.0.1
 *  @author       Tsuji
 */

(function(win, doc, App){

"use strict";

/**
 * ページ内スクロール
 *
 * @public
 * @param  {string} selector
 * @return  {App.Behaviors.SelectBox} self
 */
App.Behaviors.AnchorScroll = function (selector) {
    var self = new App.Services.Observer(),
        $el = $(selector),
        anchorScrollFlag = false;

        self._util = new App.Services.Util();

    /** ------------------------------
     *  Initialize
     ---------------------------------*/

    function init() {
      $el.on('click', function(e){
        e.preventDefault();
    
        if(anchorScrollFlag) { return; }
    
        anchorScrollFlag = true;
    
        var target = $(this).attr('href');
        if($(target).length) {
          var pos = $(target).offset().top;    
          $('html, body').animate({'scrollTop': pos}, 500, function(){
            setTimeout(function(){
              anchorScrollFlag = false;
            }, 500);
          });
        }
      });
    }

    /** ------------------------------
     *  Private
     ---------------------------------*/

    /** ------------------------------
     *  Public
     ---------------------------------*/


    // ---------- init and export ----------
    init();
    return self;
};

}(this, this.document, this.App));

