/**
 *  Validate.js
 *
 *  @project      shareshima
 *  @since        2016/06/12
 *  @version      0.0.1
 *  @author       Tsuji
 */

(function(win, doc, App){

"use strict";

/**
 * バリデーション
 *
 * @public
 * @return {App.Behaviors.Validator} self
 */
App.Behaviors.Validator = function () {
    var self = new App.Services.Observer();

    self._util = new App.Services.Util();
    //self._fm = new App.Behaviors.FlashMessenger();

    /** ------------------------------
     *  Initialize
     ---------------------------------*/
    function init () {
    }

    /** ------------------------------
     *  Private
     ---------------------------------*/

    /** ------------------------------
     *  Public
     ---------------------------------*/
    /**
     *  apiValidateEmail
     *
     *  @function　ユーザーメールアドレス ApIバリデート
     *  @public
     *  @param {string} el emailアドレス要素
     *  @param {string} form 送信フォーム
     *  @return {void}
     */
    self.apiValidateEmail = function (el, form) {
        var self = this,
            emal_value,
            $email =$(el),
            $form =$(form),
            save_email = $('#email').val(),
            change_email,
            is_apiCall = false,
            valid = false,
            $body = $('body');
            
        //self._fm = new App.Behaviors.FlashMessenger();

        // メールアドレスチェック中はモーダルの背景色を消す
        //

        // フォーカスアウト時にチェック
        $email.on('blur', function(){
            $email.removeClass('form-error');
            $email.next('.error-message').remove();
            emal_value = $('#email').val();
            // アドレス入力ありかつ登録済アドレスと異なるアドレスが入力
            if(self.checkRequired(this) && save_email !== emal_value) {
                // 一度OKだったアドレスと同じアドレスを入力した時は再チェックしない
                if(change_email !== '' && change_email === emal_value) {
                    return true;
                }
                // APIコール
                is_apiCall = true;
                $.ajax({
                  url: '/apiInternal/validateEmail',
                  type:'POST',
                  dataType: 'json',
                  data:{
                    "email": $email.val()
                  }
                })
                .done(function(data){
                  $email.next('.error-message').remove();
                  
                  if (data['status'] == -1) {
                    $email.addClass('form-error');
                    var message = data.message;
                    message = message.replace('<br>', '');
                    $email.after('<p class="error-message">'+ message +'</p>');
                    is_apiCall = false;
                    
                  } else {
                    change_email = emal_value;
                    $email.removeClass('form-error');
                    is_apiCall = false;
                  }
                });
            }
        });

        // 通信中はsubmitキャンセル
        $form.on('submit', function(e){
            if(is_apiCall) {
                e.preventDefault();
                return;
            }
        });
    }
    
    /**
     *  validMail
     *
     *  @function  emailチェック
     *  @public
     *  @param {string} mail 入力項目要素
     *  @return {boolean}
     */
    self.validMail = function (nodes) {
        var mail = $(nodes).val();
        return /^(([^\u0080-\uffff<>()\[\]\\.,;:\s@"]+(\.[^\u0080-\uffff<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(mail);
    }

    /**
     *  checkRequired
     *
     *  @function  未入力チェック
     *  @public
     *  @param {string} nodes 入力項目要素
     *  @return {boolean}
     */
    self.checkRequired = function (nodes) {
        var value = _($(nodes)).map(function(node) {
            return $(node).val();
        }).join('');
        var result = !!value;
        // placeholderと一緒の場合は空と判定
        if (self._util.getuserAgent().IE && $(nodes).val() === $(nodes).attr('placeholder')) {
            return false;
        }
        return result;
    }

    /**
     *  checkInputNum
     *
     *  @function  入力文字半角数字チェック
     *  @public
     *  @param {string} nodes 入力項目要素
     *  @return {boolean}
     */
    self.checkInputNum = function (nodes) {
        var result = _(nodes).select(function(data){
                var str = $(data).val();

                if(!self.checkNum(str)) {
                    return data;
                }
        });
        if(_.size(result) > 0){
            return false;
        }
        return true;
    }

    /**
     *  checkNum
     *
     *  @function  半角数字チェック
     *  @public
     *  @param {integer} num 数字
     *  @return {boolean}
     */
    self.checkNum = function (value) {
        var num = ''+value || null;
        var ex = new RegExp("^[0-9]+$");

        if(!num.match(ex)) {
            return false;
        }

        return true;
    }

     /**
     *  checkDigit
     *
     *  @function 桁数チェック
     *  @public
     *  @param {jquery object} $nodes 入力要素
     *  @param {integer} length 桁数
     *  @return {boolean}
     */
    self.checkDigit = function ($nodes, length) {
        var value = _($nodes).map(function(node) {
            return $(node).val();
        }).join('');
        return String(value).length === length;
    }

    /**
     *  checkSum
     *
     *  @function  金額チェック
     *  @public
     *  @param {string} nodes 入力項目要素
     *  @return {boolean}
     */
    self.checkSum = function (nodes) {
        var result = _(nodes).select(function(data){
            var str = $(data).val();
            var ex1 = new RegExp("[0-9|０-９|一|二|三|四|五|六|七|八|九|〇]円|十円|百円|千円|万円|億円|兆円");
            var ex2 = new RegExp("[￥|¥][0-9|０-９|一|二|三|四|五|六|七|八|九|〇]");
            if(str.match(ex1) || str.match(ex2)) {
                return data;
            }
        });
        if(_.size(result) > 0){
            return false;
        }
        return true;
    }

    /**
     *  checkAccountName
     *
     *  @function  入力口座名文字種チェック
     *  @public
     *  @param {string} node 入力項目要素
     *  @return {boolean}
     */
    self.checkAccountName = function (node) {
        return self.checkAccountStr($(node).val());
    }

    /**
     *  checkAccountStr
     *
     *  @function  口座名文字種チェック
     *  @public
     *  @see https://www.mizuhobank.co.jp/direct/goriyo/furikomi/uketorininmei.html
     *  @param {string} accountName 入力文字
     *  @return {boolean}
     */
    self.checkAccountStr = function (accountName) {
        var accountNameRegExp = /(?:[　（）／．ー])|(?:[ア-ン])|(?:[０-９])|(?:[Ａ-Ｚ])|(?:[\u30f4])/;
        var checker = new RegExp('^(?:' + accountNameRegExp.source + ')*$');
        return checker.test(accountName);
    }

    /**
     *  validateRequired
     *
     *  @function 必須入力チェック(テキストボックス、セレクトボックス、テキストエリア)
     *  @public
     *  @param {string} nodes 必須項目要素
     *  @return {boolean}
     */
    self.validateRequired = function (nodes) {
        var $nodes = $(nodes),
        result = _($nodes).select(function(data){
            if(!self.checkRequired($(data))) {
                return data;
            }
        });
        // 未入力チェック
        if(_.size(result) > 0){
            return false;
        }
        return true;
    }

    /**
     *  checkedRequired
     *
     *  @function 必須入力チェック(チェックボックス、ラジオボタン)
     *  @public
     *  @param {string} nodes 必須項目要素
     *  @return {boolean}
     */
    self.checkedRequired = function (nodes) {
        var $nodes = $(nodes),
            valid = _($nodes).any(function(node) {
                return $(node).is(':checked');
            });
        return valid;
    }

     /**
     *  validateSelected
     *
     *  @function　必須入力チェック（どれか一つでも入力されていたらOK）
     *  @public
     *  @param {string} nodes 必須項目
     *  @return {boolean}
     */
    self.validateSelected = function (nodes) {
        var $nodes = $(nodes),
        valid = _($nodes).any(function(node) {
            return !!$(node).val();
        });
        return valid;
    }

     /**
     *  checkNumberRange
     *
     *  @function 入力数値が指定した範囲かチェック
     *  @public
     *  @param {integer} inputNum 入力数値
     *  @param {integer} minNum 最小値
     *  @param {integer} maxNum 最大値
     *  @return {boolean}
     */
    self.checkNumberRange = function (inputNum, minNum, maxNum) {
        return (inputNum >= minNum && inputNum <= maxNum)
    }

     /**
     *  checkNumberDivisible
     *
     *  @function 指定した数値で割り切れるかチェック
     *  @public
     *  @param {integer} dividendNum 入力数値
     *  @param {integer} divisorNum 除数
     *  @return {boolean}
     */
    self.checkNumberDivisible = function (dividendNum, divisorNum) {
        return (dividendNum % divisorNum === 0)
    }

     /**
     *  validateTel
     *
     *  @function 電話番号チェック
     *  @public
     *  @param {string} nodes 入力項目要素
     *  @return {boolean}
     */
    self.validateTel = function (nodes) {
        var tel = $(nodes).val();
        //数字のみの場合
        var ex = new RegExp("^[0-9]+$");
        if(!tel.match(ex)) {
          var telNum = tel.replace(/[^0-9]/g, '');
          
            if(telNum.length == 10 || telNum.length == 11) {
                return /0[0-9]{1,4}-[0-9]{1,6}(-[0-9]{0,5})?/.test(tel);
            } else {
                return false;
            }
        } else {
            if(tel.length == 10 || tel.length == 11) {
                return true;
            } else {
                return false;
            }
        }
    }

     /**
     *  validateUserTel
     *
     *  @function 電話番号チェック
     *  @public
     *  @param {string} num 電話番号
     *  @return {boolean}
     */
    self.validateUserTel = function (num) {
        var tel = num;
        //数字のみの場合
        var ex = new RegExp("^[0-9]+$");
        if(!tel.match(ex)) {
          var telNum = tel.replace(/[^0-9]/g, '');
          
            if(telNum.length == 10 || telNum.length == 11) {
                return /0[0-9]{1,4}-[0-9]{1,6}(-[0-9]{0,5})?/.test(tel);
            } else {
                return false;
            }
        } else {
            if(tel.length == 10 || tel.length == 11) {
                return true;
            } else {
                return false;
            }
        }
    }

     /**
     *  validateUserTel
     *
     *  @function URLチェック
     *  @public
     *  @param {string} nodes
     *  @return {boolean}
     */
    self.validateUrl = function (nodes) {
        var url = $(nodes).val();
        return /^(https?|ftp)(:\/\/[-_.!~*\'()a-zA-Z0-9;\/?:\@&=+\$,%#]+)$/.test(url);
    }

    self.validateYoutubeUrl = function (nodes) {
        var url = $(nodes).val();
        return /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/.test(url);
    }

     /**
     *  validateKana
     *
     *  @function カタカナチェック
     *  @public
     *  @param {string} nodes
     *  @return {boolean}
     */
    self.validateKana = function (nodes) {
        var url = $(nodes).val();
        return /^[ァ-ヶー　]+$/.test(url);
    }


    /**
     *  clearError
     *
     *  @function　エラーメッセージをクリアする
     *  @public
     *  @param {string} el
     *  @return {boolean}
     */
    self.clearError = function(el) {
        $(el)
            .removeClass('form-error')
            .next('.error-message, .js-error-message').remove();
    }

    /**
     *  showError
     *
     *  @function　エラーメッセージ表示
     *  @public
     *  @param {string} el
     *  @param {string} msg
     *  @return {boolean}
     */
    self.showError = function (el, msg) {
        $(el)
            .addClass('form-error')
            .after('<div class="error-message js-error-message">' + msg + '</div>');
    }

    /**
     *  showErrorGrid
     *
     *  @function　グリット配置した要素のエラーメッセージ表示
     *  @public
     *  @param {string} el
     *  @param {string} msg
     *  @return {boolean}
     */
    self.showErrorGrid = function (el, wrap, msg) {
        if ($(wrap).next().hasClass('error-message')){
            //エラーメッセージがあった場合はその次に表示
            var error = $(wrap).nextAll('.error-message').length;
            $(el).addClass('form-error');
            if(error < 2) {
                $(wrap).next('.error-message').after('<div class="error-message js-error-message">' + msg + '</div>');
            } else {
                $(wrap).next('.error-message').next().after('<div class="error-message js-error-message">' + msg + '</div>');
            }
        } else {
            $(el).addClass('form-error');
            $(wrap).after('<div class="error-message js-error-message">' + msg + '</div>');
        }
    }

    // ---------- init and export ----------
    init();
    return self;
};

}(this, this.document, this.App));
