/**
 *  IeBlur.js
 *
 *  @project      shareshima
 *  @since        2015/07/31
 *  @version      0.0.1
 *  @author       K.T.T (Kodama.Tsuji.Tamura)
 */

(function(win, doc, App){

"use strict";

/**
 * ie10用のブラー
 *
 * @public
 * @param {string} selector
 * @return {App.Behaviors.IeBlur}
 */
App.Behaviors.IeBlur = function (selector) {
    var self = new App.Services.Observer(),
        $el = $(selector);
        
        self._util = new App.Services.Util();

    /** ------------------------------
     *  Initialize
     ---------------------------------*/
    function init () {
      var filterTemp = '<svg xmlns="http://www.w3.org/2000/svg" id="blur-temp">';
          filterTemp += '<defs>';
          filterTemp += '<filter id="blur">';
          filterTemp += '<feGaussianBlur stdDeviation="5" />';
          filterTemp += '</filter>';
          filterTemp += '</defs>';
          filterTemp += '</svg>';
    
    
        $('body').append(filterTemp);
    
        $el.each(function(index){
          if($(this).parents().hasClass('is-login')) { return; }
          //テキスト svg化
          var txt = $(this).text();
          if(txt.length){
            var fontSize = $(this).css('font-size').replace('px', '');
            var svgW = $(this).width();
            var textTemp = '<svg width="' + svgW + '" height="' + fontSize + '" viewBox="0 -' + fontSize + ' ' + svgW + ' ' + fontSize + '" xmlns="http://www.w3.org/2000/svg">';
                textTemp += '<text x="0" y="0" font-size="' + fontSize + '" stroke="black" text-anchor="start" stroke-width="0.1" filter="url(#blur)">' + txt +'</text>';
                textTemp += '</svg>';
            $(this).text('');
            $(this).append(textTemp);
          }
    
          //icon 背景画像 svg化
          //評価
          if($(this).hasClass('icon-evaluation')){
            var iconW = $(this).width();
            var iconH = $(this).height();
            var url = $(this).css('background-image').replace(/\url\(|\)/g, '');
            var iconTemp = '<svg width="' + iconW + '" height="' + iconH + '">';
                iconTemp += '<image xlink:href=' + url +' width="' + iconW + '" height="' + iconH + '" filter="url(#blur)"></image>';
                iconTemp += '</svg>';
            $(this).css({'background-image': 'none'});
            $(this).append(iconTemp);
    
          }
          //icon font svg化
          //認証系 メール・電話・facebook
          if($(this).hasClass('icon-mail') || $(this).hasClass('icon-tel') || $(this).hasClass('icon-shareshima') || $(this).hasClass('icon-parson')){
            var iconFontW = $(this).width();
            var iconFontH = $(this).height();
            var className = $(this).attr('class');
            var iconTemp = '<svg width="' + iconFontW + '" height="' + iconFontH + '" class="' + className + '">';
                iconTemp += '<image width="' + iconFontW + '" height="' + iconFontH + '" filter="url(#blur)"></image>';
                iconTemp += '</svg>';
    
            $(this).parent().append(iconTemp);
            $(this).remove();
          }
    
          //画像 svg化
          if($(this).attr('src') != undefined){
            var imgW = $(this).width();
            var imgH = $(this).height();
            var src = $(this).attr('src');
            var imgTemp = '<svg width="' + imgW + '" height="' + imgH + '" class="js-ie-svg">';
                imgTemp += '<image xlink:href="' + src +'" width="' + imgW + '" height="' + imgH + '" filter="url(#blur)"></image>';
                imgTemp += '</svg>';
            $(this).parent().append(imgTemp);
            $(this).remove();
          }
        });
    
        //svg 画像 レスポンシブ対応
        $(window).on('resize', function() {
          $el = $('.js-ie-svg');
          $el.each(function(){
            var wrapW = $(this).parent().width();
            var imgW = $(this).width();
            var imgH = $(this).height();
            $(this).attr({'width':wrapW});
            $(this).find('image').attr({'width':wrapW});
            $(this).attr({'height':imgH * (wrapW / imgW)});
            $(this).find('image').attr({'height':imgH * (wrapW / imgW)});
          })
        });
    }
     

    /** ------------------------------
     *  Public
     ---------------------------------*/

    // ---------- init and export ----------
    init();
    return self;
};

}(this, this.document, this.App));

