/**
 *  FileNameDisplay.js
 *
 *  @project      shareshima
 *  @since        2017/12/21
 *  @version      0.0.1
 *  @author       Tsuji
 */

(function(win, doc, App){

"use strict";

/**
 * ページ内スクロール
 *
 * @public
 * @param  {string} selector
 * @return {App.Behaviors.FileNameDisplay} self
 */
App.Behaviors.FileNameDisplay = function (selector) {
    var self = new App.Services.Observer(),
        $el = $(selector);
        self._util = new App.Services.Util();

    /** ------------------------------
     *  Initialize
     ---------------------------------*/

    function init() {
    }

    /** ------------------------------
     *  Private
     ---------------------------------*/

    /** ------------------------------
     *  Public
     ---------------------------------*/
     /**
     *  getFileName
     *
     *  @function 指定した数値で割り切れるかチェック
     *  @public
     *  @return {string}
     */
    self.getFileName = function (selector) {
        var $el = $(selector);

        return $el.prop('files')[0].name;
    }


    // ---------- init and export ----------
    init();
    return self;
};

}(this, this.document, this.App));

