/**
 *  FoodsSearch.js
 *
 *  @project      shareshima
 *  @since        2017/12/21
 *  @version      0.0.1
 *  @author       Tsuji
 */

(function(win, doc, App){

"use strict";

/**
 * 食品検索
 *
 * @public
 * @param  {string} selector
 * @return  {App.Behaviors.FoodsSearch} self
 */
App.Behaviors.FoodsSearch = function (selector) {
    var self = new App.Services.Observer(),
        $el = $(selector);

        self._util = new App.Services.Util();

    /** ------------------------------
     *  Initialize
     ---------------------------------*/

    function init() {
      $el.on('submit',function(e){
          e.preventDefault();
          foodsSearch();
      });
    }

    /** ------------------------------
     *  Private
     ---------------------------------*/
    /**
     *  foodsSearch
     *
     *  @function　入力値とカテゴリ選択から検索
     *  @public
     *  @param {obj} $zipCode 郵便番号要素
     *  @return {void}
     */
      var foodsSearch = function() {
        //検索フリーワード取得
        var searchWord = $el.find('.js-item-search-word').val();
        // カテゴリの選択データ取得
        var category = $el.find('.js-item-search-category option:selected').val();

        var $form = $('<form/>', {id: 'FoodsSearchForm', action: '/foods'});
        $form.append($('<input />', {type: 'hidden', name: 'q', value: searchWord}));
        if(category){
          $form.append($('<input />', {type: 'hidden', name: 'category', value: category}));
        }
        $form.appendTo(document.body).submit();
      }
      
    /** ------------------------------
     *  Public
     ---------------------------------*/


    // ---------- init and export ----------
    init();
    return self;
};

}(this, this.document, this.App));

