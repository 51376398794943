/**
 *  Observer.js
 *
 *  @project      shareshima
 *  @since        2015/07/14
 *  @version      0.0.1
 *  @author       K.T.T (Kodama.Tsuji.Tamura)
 */

(function(win, doc, App){

"use strict";

/**
 * カスタムイベントを登録・通知するオブザーバークラス
 * TODO: ※※※※※※ コピペコードで、冗長っぽい ※※※※※※ 
 *
 * @public
 * @return  {object}
 */
App.Services.Observer = function () {
    var ed = new EventDispatcher();
    function dispatchEvent(typ, opt_evt) {
        ed.dispatchEvent(typ, opt_evt);
    }

    /** ------------------------------
     *  Private
     ---------------------------------*/
    function EventDispatcher () {
        var self = {};

        /**
         *  @param {string}   typ イベント名
         *  @param {?Object=} opt_evt 引数に渡すオブジェクト（オプション）
         *
         *  @return {void}
         */
        self.handlers = {};
        self.dispatchEvent = function(typ, opt_evt) {
            if (!typ) {
                throw new Error("INVALID EVENT TYPE " + typ);
            }

            var obj = this.handlers || (this.handlers = {}),
                arr = obj[typ] || [],
                evt = opt_evt || {},
                len, i, fnc;


            // handle specified event type
            for (i = 0, len = arr.length; i < len; i++) {
                (fnc = arr[i]) && fnc.call(this, evt);
            }

            // handle wildcard "*" event
            arr  = obj["*"] || [];
            for (i = 0, len = arr.length; i < len; i++) {
                (fnc = arr[i]) && fnc.call(this, evt);
            }
        };

        /**
         *  @param {string} typ イベント名
         *  @param {function(evt:Object):void} fnc ハンドラ
         *
         *  @return {void}
         */
        self.addEventListener = function(typ, fnc) {
            if (!typ) {
                throw new Error("INVALID EVENT TYPE " + typ + " " + fnc);
            }

            var obj = this.handlers || (this.handlers = {});
            ( obj[typ] || (obj[typ] = []) ).push(fnc);
        };

        /**
         *  @param {string} typ イベント名
         *  @param {function(evt:object):void} fnc ハンドラ
         */
        self.removeEventListener = function(typ, fnc) {
            if (!typ) {
                throw new Error("INVALID EVENT TYPE " + typ);
            }

            var obj = this.handlers || (this.handlers = {}),
                arr = obj[typ] || [],
                i = arr.length;

            while(i) {
                arr[--i] === fnc && arr.splice(i, 1);
            }
        };
        return self;
    }
    /**
     *  e.g. delegateMethod("addEventListener").from(instance);
     *
     *  @param {string}  methodName
     *  @return {function}
     */
    function delegateMethod(methodName) {
        /*--------------------------------------------
            PRIVATE
        --------------------------------------------*/
        var instance = null,
            context;
        /*--------------------------------------------
            PUBLIC
        --------------------------------------------*/
        /**
         *  @param {Object}  ins
         *  @param {Object} opt_context
         */
        function from(ins, opt_context) {
            if (!ins[methodName]) {
                throw new Error(ins + " has no method : " + methodName);
            }
            instance = ins;
            context = arguments.length >= 2 ? opt_context : ins;
            return method;
        }
        function method() {
            return instance[methodName].apply(context, arguments);
        }
        /*--------------------------------------------
            EXPORT
        --------------------------------------------*/
        return {
            from : from
        };
    }
    /*--------------------------------------------
        EXPORT
    --------------------------------------------*/
    return {
        dispatchEvent       : dispatchEvent,
        addEventListener    : delegateMethod("addEventListener").from(ed),
        removeEventListener : delegateMethod("removeEventListener").from(ed),
        ed :  ed
    };
};

}(this, this.document, this.App));
