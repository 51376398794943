/**
 *  Tag.js
 *
 *  @project      nagano
 *  @since        2019/11/13
 *  @version      0.0.1
 *  @author       Ishibashi
 */

(function(win, doc, App){

"use strict";

/**
 *  a要素内のタグクリック時の処理
 *
 * @public
 * @param {string} selector
 * @return {App.Behaviors.Tag}
 */
App.Behaviors.StoreValidator = function () {
    var self = new App.Services.Observer(),
        $storeForm = $('#store-registration'),
        $storeSubmit = $('.js-store-registration-submit'),
        $siteForm = $('#site-registration'),
        $siteSubmit = $('.js-site-registration-submit'),
        is_apiCall = false,
        calling_api = false;
    self._util = new App.Services.Util();
    self._validate = new App.Behaviors.Validator();
    /** ------------------------------
     *  Initialize
     ---------------------------------*/
    function init () {
        validateSetting();
    }

    /** ------------------------------
     *  Private
     ---------------------------------*/
    /**
     *
     *
     * @function 各項目のバリデード定義
     * @private
     * @return {void}
     */
     function validateSetting(){

        // 名前
        $('#name').on('validate', function(event){
            self._validate.clearError($(this));
            var valid = self._validate.validateRequired(this);
            if(!valid) {
                self._validate.showError(this, '未入力です。');
            }
        });

        // メール
        $('#email').on('validate', function(event){
            self._validate.clearError($(this));
            var valid = self._validate.validateRequired(this);
            if(!valid) {
                self._validate.showError(this, '未入力です。');
            } else {
                var validEmail = self._validate.validMail(this);
                if(!validEmail) {
                    self._validate.showError(this, 'メール形式で入力してください。');
                }
            }
        });

        // 電話番号
        $('#tel').on('validate', function(event){
            self._validate.clearError($(this));
            var valid = self._validate.validateRequired(this);
            if(!valid) {
                self._validate.showError(this, '未入力です。');
            } else {
              var validTel = self._validate.validateTel(this);
              if(!validTel) {
                  self._validate.showError(this, '電話番号の形式で入力して下さい。');
              }
            }
        });


        //店舗所在地域 共通
        $('.js-area-validate-checkbox').on('validate', function(event){
            $('.store-location-area').parents('.js-validate-wrap')
                .removeClass('form-error')
                .next('.error-message, .js-error-message').remove();
            
            var valid = $('.js-area-validate-checkbox:checked').length;
            
            if(!valid) {
                self._validate.showError($('.store-location-area').parents('.js-validate-wrap'), '地域を選択して下さい。');
            }
        });
        
        if ($('#master-mall-post-site-order-category-id').val() == 1) {

            // 掲載サービス名 
            $('#i-service-shop-name').on('validate', function(event){
                self._validate.clearError($(this));
                var valid = self._validate.validateRequired(this);
                if(!valid) {
                    self._validate.showError(this, '未入力です。');
                }
            });
            
            //郵便番号 ※飲食店
            $('#shop-zipcode').on('validate', function(event){
                self._validate.clearError($(this));
                var valid = self._validate.validateRequired(this);
                if(!valid) {
                    self._validate.showError(this, '未入力です。');
                } else {
                  var validNum = $(this).val().match(/^[0-9]+$/);
                  if(!validNum) {
                      self._validate.showError(this, '半角数字で入力してください。');
                  }
                }
            });
            // 住所
            $('#shop-address').on('validate', function(event){
                self._validate.clearError($(this));
                var valid = self._validate.validateRequired(this);
                if(!valid) {
                    self._validate.showError(this, '未入力です。');
                }
            });
            
            //掲載URL ※飲食店
            $('#posting-url').on('validate', function(event){
                self._validate.clearError($(this));
                var valid = self._validate.validateRequired(this);
                if(!valid) {
                    if ($('#is-no-hp-1').prop('checked') != true) {
                        self._validate.showError(this, '未入力です。');
                    }
                } else {
                  var validURL = self._validate.validateUrl(this);
                  if(!validURL) {
                      self._validate.showError(this, 'URLの形式で入力して下さい。');
                  }
                }
            });

            
            if ($('#is_no_hp').prop('checked') == true) {
              // 店舗電話番号
              $('#shop-tel').on('validate', function(event){
                  self._validate.clearError($(this));
                  var valid = self._validate.validateRequired(this);
                  if(!valid) {
                      self._validate.showError(this, '未入力です。');
                  }
              });
            }
            
            
            // 説明
            $('#store-description').on('validate', function(event){
                self._validate.clearError($(this));
                var valid = self._validate.validateRequired(this);
                if(!valid) {
                    self._validate.showError(this, '未入力です。');
                }
            });
            
        } else {
          //デリバリー
            // 掲載サービス名 
            $('#d-service-shop-name').on('validate', function(event){
                self._validate.clearError($(this));
                var valid = self._validate.validateRequired(this);
                if(!valid) {
                    self._validate.showError(this, '未入力です。');
                }
            });
            //掲載URL ※飲食店
            $('#url').on('validate', function(event){
                self._validate.clearError($(this));
                var valid = self._validate.validateRequired(this);
                if(!valid) {
                    self._validate.showError(this, '未入力です。');
                } else {
                  var validURL = self._validate.validateUrl(this);
                  if(!validURL) {
                      self._validate.showError(this, 'URLの形式で入力して下さい。');
                  }
                }
            });
        }
        

        // 同意チェック
        $('#store-registration-agree').on('validate', function(event){            
            self._validate.clearError($('#store-registration').find('.js-store-registration-agree'));
            var valid = self._validate.checkedRequired(this);
            if(!valid) {
                self._validate.showError($('#store-registration').find('.js-store-registration-agree'), '利用規約とプライバシーポリシーを確認してチェックを入れてください。');
            }
        });


     }    /** ------------------------------
     *  Public
     ---------------------------------*/

    // ---------- init and export ----------
    init();
    return self;
};

}(this, this.document, this.App));

