/**
 *  CancelNewLine.js
 *
 *  @project      nagano
 *  @since        2020/02/17
 *  @version      0.0.1
 *  @author       Ishibashi
 */

(function(win, doc, App){

"use strict";

/**
 * 改行禁止
 *
 * @public
 * @param  {string} selector
 * @return  {App.Behaviors.CancelNewLine} self
 */
App.Behaviors.CancelNewLine = function () {
    var self = new App.Services.Observer();

        self._util = new App.Services.Util();


    /** ------------------------------
     *  Initialize
     ---------------------------------*/

    function init() {

        $('.js-cancelNewLine').on('keydown', function (e) {
            // e.key == 'Enter'でエンターキーが押された場合の条件を設定
            if (e.key == 'Enter') {
                // 何もせずに処理を終える
                return false;
            }
        });
    }

    /** ------------------------------
     *  Private
     ---------------------------------*/
      
    /** ------------------------------
     *  Public
     ---------------------------------*/


    // ---------- init and export ----------
    init();
    return self;
};

}(this, this.document, this.App));

