/**
 *  AddressSearch.js
 *
 *  @project      shareshima
 *  @since        2017/12/21
 *  @version      0.0.1
 *  @author       Tsuji
 */

(function(win, doc, App){

"use strict";

/**
 * 郵便番号から住所を検索
 *
 * @public
 * @param  {string} selector
 * @return  {App.Behaviors.AddressSearch} self
 */
App.Behaviors.AddressSearch = function (selector) {
    var self = new App.Services.Observer(),
        $el = $(selector),
        calling_api = false;

        self._util = new App.Services.Util();
        self._fm = new App.Behaviors.FlashMessenger();

    /** ------------------------------
     *  Initialize
     ---------------------------------*/

    function init() {
          
      $el.each(function(){
        var $this = $(this),
            $search = $this.find('.js-zip-search'),
            $zipCode = $this.find('.js-zip-code');
  
        //郵便番号の入力値の調整
        $zipCode.on('blur', function(){
          zipCodeReplace($(this));
        });
    
        //apiに問い合わせ
        $search.on('click',function(e){
          e.preventDefault();
          //2重呼び出し防止
          if (calling_api) { return; }
          calling_api = true;
          zipCodeCallApi($this);

        });
      });
    }

    /** ------------------------------
     *  Private
     ---------------------------------*/
    /**
     *  zipCodeReplace
     *
     *  @function　郵便番号の入力値の調整
     *  @public
     *  @param {obj} $zipCode 郵便番号要素
     *  @return {void}
     */
      var zipCodeReplace = function($zipCode) {
          var zipcode = $zipCode.val();
          zipcode = zipcode.replace(/[０-９]/g, function(s) {
            return String.fromCharCode(s.charCodeAt(0) - 0xFEE0);
          });
          zipcode = zipcode.replace(/[^0-9]/g, '');
          $zipCode.val(zipcode);
      }

    /**
     *  zipCodeCallApi
     *
     *  @function　apiの呼び出し
     *  @public
     *  @param {obj} $zipWrap ラッパー要素
     *  @return {void}
     */
      var zipCodeCallApi = function($zipWrap) {
        
          var $zipCode = $zipWrap.find('.js-zip-code');
          var $prefectures = $zipWrap.find('.js-zip-prefectures');
          var $address = $zipWrap.find('.js-zip-address');
          var $area = $zipWrap.find('.js-zip-area');
          
          var zipcode = $zipCode.val();
          
          if(zipcode.length != 7) {
            calling_api = false;
            return;
          }

          var type = 'GET',
              url = '/apiInternal/zipcloud',
              params = {"zipcode": zipcode},
              datatype = 'json';
          self._util
              .ajax(type, url, params, datatype)
              .done(function(res) {
                  if(res.status === 200 && res.data[0]) {
                      //タブレットだとオプションのクリックではうごかない
                      if ($('html').hasClass('sp_tb')){
                        $prefectures.val(res.data[0].prefcode);
                        $prefectures.change();
                      } else {
                        $prefectures.prev('.sod_list_wrapper').find('.sod_option').eq(res.data[0].prefcode).click();
                      }
                      $address.val(res.data[0].address2);
                      $area.val(res.data[0].address3);
                  }
              })
              .fail(function(data) {
                // error message
                  self._fm.flashMessageDanger('エラーが発生しました、時間を置いてお試し下さい、申し訳ありません');
              })
              .always(function() {
                  calling_api = false;
              });
      }
      
    /** ------------------------------
     *  Public
     ---------------------------------*/


    // ---------- init and export ----------
    init();
    return self;
};

}(this, this.document, this.App));

