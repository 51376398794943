/**
 *  SelectBox.js
 *
 *  @project      shareshima
 *  @since        2017/12/21
 *  @version      0.0.1
 *  @author       Tsuji
 */

(function(win, doc, App){

"use strict";

/**
 * カスタムセレクトボックス
 *
 * @public
 * @param  {obj} $obj
 * @return  {App.Behaviors.SelectBox} self
 */
App.Behaviors.SelectBox = function ($obj) {
    var self = new App.Services.Observer();

        self._util = new App.Services.Util();

    /** ------------------------------
     *  Initialize
     ---------------------------------*/

    function init() {        
        if(!$obj.val()){
          $obj.parents('.js-select').addClass('is-placeholder');
        } else {
          $obj.parents('.js-select').removeClass('is-placeholder');
        }
    }

    /** ------------------------------
     *  Private
     ---------------------------------*/

    /** ------------------------------
     *  Public
     ---------------------------------*/


    // ---------- init and export ----------
    init();
    return self;
};

}(this, this.document, this.App));

