/**
 *  DiscerningSearch.js
 *
 *  @project      nagano
 *  @since        2019/11/26
 *  @version      0.0.1
 *  @author       Hatsugai
 */

(function(win, doc, App){

"use strict";

/**
 * こだわり条件から探す
 *
 * @public
 * @param  {string} selector
 * @return  {App.Behaviors.DiscerningSearch} self
 */
App.Behaviors.DiscerningSearch = function (selector) {
    var self = new App.Services.Observer(),
        $el = $(selector),
        $accordionTarget = $el.find('.js-accordion-target'),
        $salesDateAccordionTarget = $el.find('.js-sales-date-accordion-target'),
        $areaTarget = $el.find('.js-discerning-area-target'),
        $salesDateTarget = $el.find('.js-discerning-sales-date-target'),
        $submitBtn = $el.find('.js-discerning-submit'),
        areas = AreaApp.masterNaganoAreas,
        cities = AreaApp.masterNaganoCities;

    /** ------------------------------
     *  Initialize
     ---------------------------------*/

    function init() {
      // 生産エリア 初期設定
      $el.find('.js-discerning-select-area').each(function() {
        var $that = $(this);
        var id = $(this).data('id');
        var fragment = document.createDocumentFragment();
        $.each(cities, function(i) {
          if (cities[i].cat_id == id) {
            fragment.appendChild(createOptionElm(cities[i]));
          }
        });
        $(this).find('label').text(areas[id]);
        $(this).find('select')[0].appendChild(fragment);

        // inner change event
        var $inputElm = $(this).find('input');
        var $selectElm = $(this).find('select');
        $inputElm.on('change', function() {
          var $this = $(this);
          setTimeout(function() {
            if ($this.prop('checked')) {
              $selectElm.prop('disabled', false);
              $that.addClass('is-active');
            } else {
              $selectElm.prop('disabled', true);
              $that.removeClass('is-active');
            }
          }, 10);
        });
      });
      function createOptionElm(data) {
        var option = document.createElement('option');
        option.innerText = data.text;
        option.value = data.value;
        return option;
      }

      setTimeout(function() {
        $el.find('.mdb-select').materialSelect();
      }, 10);

      // アコーディオン処理
      $el.find('.js-accordion-content').slideUp(0);
      $accordionTarget.on('change', function() {
        var $this = $(this);
        var $content = $(this).parent().next();
        if ($content.hasClass('js-accordion-content')) {
          setTimeout(function() {
            if ($this.prop('checked')) {
              $content.slideDown();
            } else {
              $content.slideUp();
            }
          }, 10);
        }
      });

      // 提供可能時期 月アコーディオン
      $salesDateAccordionTarget.on('change', function() {
        var $this = $(this);
        var $content = $this.parent().parent().next();
        if ($content.hasClass('js-accordion-content')) {
          setTimeout(function() {
            if ($this.val() == '1') {
              $content.slideUp();
            } else {
              $content.slideDown();
            }
          }, 10);
        }
      });

      $submitBtn.on('click', function(e) {
        e.preventDefault();
        var link = createLink();
        if (link) {
          location.href = link;
        }
      });
    }

    function createLink() {
      var params = [];
      // 親要素のみ
      var $target = $el.find('.js-discerning-target:checked');
      $target.each(function() {
        if ($(this).data('param')) {
          params.push($(this).data('param'));
        }
      });

      // 小要素がある場合
      var $parentTarget = $el.find('.js-discerning-parent-target:checked');
      $parentTarget.each(function() {
        var $this = $(this);

        var childParams = [];
        var parentParam = $(this).data('param');
        var $content = $(this).parent().next();
        if ($content.hasClass('js-discerning-child-content')) {
          var $childTarget = $content.find('.js-discerning-child-target:checked');
          $childTarget.each(function() {
            childParams.push($(this).data('param'));
          });
        }
        if (parentParam && childParams.length) {
          params.push(parentParam + '=' + childParams.join(','));
        }
      });

      // 生産エリア
      if ($areaTarget.length) {
        var areaParams = [];
        var cityParams = [];
        var areaParentParam = "area_ids";
        var cityParentParam = "city_ids";

        if ($areaTarget.prop('checked')) {
          var $content = $areaTarget.parent().next();
          if ($content.hasClass('js-discerning-area-content')) {
            $content.find('.js-discerning-select-area').each(function() {
              if ($(this).find('input').prop('checked')) {
                if ($(this).find('select').val()) {
                  cityParams.push($(this).find('select').val());
                } else {
                  areaParams.push($(this).find('input').val());
                }
              }
            });
          }
          if (areaParams.length) {
            params.push(areaParentParam + '=' + areaParams.join(','));
          }
          if (cityParams.length) {
            params.push(cityParentParam + '=' + cityParams.join(','));
          }
        }
      }

      // 提供可能時期（販売期間）
      if ($salesDateTarget.length) {
        if ($salesDateTarget.prop('checked')) {
          var $content = $salesDateTarget.parent().next();
          var $monthContent = $content.find('.js-discerning-sales-month-content');
          if ($content.hasClass('js-discerning-sales-date-content')) {
            var $checked = $content.find('input:checked');
            if ($checked.val() == '1') {
              // 通年
              params.push('is_suppliable_term=0');
            } else if ($checked.val() == '2') {
              // 月
              var monthList = [];
              $monthContent.find('input:checked').each(function() {
                monthList.push($(this).val());
              });
              if (monthList.length) {
                params.push('is_suppliable_term=1');
                params.push('sales_date_ids=' + monthList.join(','));
              }
            }
          }
        }
      }

      params.join('&');
      if (params.length) {
        return "/foods?" + params.join('&');
      }

      return false;
    }

    // ---------- init and export ----------
    init();
    return self;
};

}(this, this.document, this.App));
