/**
 *  SelectBox.js
 *
 *  @project      shareshima
 *  @since        2017/12/21
 *  @version      0.0.1
 *  @author       Tsuji
 */

(function(win, doc, App){

"use strict";

/**
 * カスタムセレクトボックス
 *
 * @public
 * @param  {string} selector
 * @return  {App.Behaviors.SelectBox} self
 */
App.Behaviors.UserFixedMenu = function (selector) {
    var self = new App.Services.Observer(),
        $el = $(selector),
        $mFixed = $el.find('.js-user-menu-fixed'),
        $plFixed = $el.find('.js-page-link-fixed'),
        scrollVal,
        scrollLeftVal,
        windowH;
        
    self._util = new App.Services.Util();
      
    /** ------------------------------
     *  Initialize
     ---------------------------------*/

    function init() {
  
      getWindowInfo();
      fixedMenu();
  
      $(window).on('scroll resize',function(){
        getWindowInfo();
        fixedMenu();
      });
    }

    /** ------------------------------
     *  Private
     ---------------------------------*/
    /**
     *  getWindowInfo
     *
     *  @function スクロール値と画面の高さ取得
     *  @private
     *  @return {void}
     */
    var getWindowInfo = function() {
      scrollVal = $(window).scrollTop();
      scrollLeftVal = $(window).scrollLeft();
      windowH = window.innerHeight ? window.innerHeight: $(window).height();
    }

    /**
     *  fixedMenu
     *
     *  @function メニュー位置調整
     *  @private
     *  @return {void}
     */
    var fixedMenu = function() {
      var mPos = $el.offset().top;

      $mFixed.css({left: $el.offset().left - scrollLeftVal});
      if($plFixed.length) {
        $plFixed.css({left: $el.offset().left - scrollLeftVal});
      }

      if(scrollVal <= mPos) {
        $el.removeClass('is-fixed');
      } else {
        $el.addClass('is-fixed');
      }
    }

    /** ------------------------------
     *  Public
     ---------------------------------*/


    // ---------- init and export ----------
    init();
    return self;
};

}(this, this.document, this.App));

