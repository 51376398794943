/**
 *  MasterNaganoCityBuild.js
 *
 *  @project      nagano
 *  @since        2019/12/04
 *  @version      0.0.1
 *  @author       Ishibashi
 */

(function(win, doc, App){

"use strict";

/**
 * 生産エリアプルダウン生成
 *
 * @public
 * @param  {string} selector
 * @return  {App.Behaviors.MasterNaganoCityBuild} self
 */
App.Behaviors.MasterNaganoCityBuild = function (selector) {
    var self = new App.Services.Observer(),
        $el = $(selector),
        $itemWrap = $el.find('.js-nagano-city-items-wrap'),        
        $item = $el.find('.js-nagano-city-items'),
        $trigger = $el.find('.js-nagano-city-trigger'),
        $city = $el.find('.js-nagano-city-select'),
        $addTrigger = $('.js-add-nagano-city-trigger'),
        $removeTrigger = $('.js-remove-nagano-city');
        
        self._util = new App.Services.Util();
        

    /** ------------------------------
     *  Initialize
     ---------------------------------*/

    function init() {
 
      
      $el.find('.mdb-select').materialSelect();
      
      $item.each(function(){
          var $target = $(this).find('.js-nagano-city-select');
          
          if(!$target.val()) {
              $target.prop("disabled", true);
          } else {
            $(this).find('.js-nagano-city-trigger').prop('checked', true);
          }
      });
      

      //selectの表示制御
      $city.selectOrDie({
        size: 6,
        onChange: function() {        
          App.Behaviors.SelectBox($(this));
        }
      });
      
      
      $(document).on('change', '.js-nagano-city-trigger', function(){
          var $item = $(this).parents('.js-nagano-city-items'),
              $select = $item.find('.js-nagano-city-select');
          if ($(this).prop('checked')) {
            $select.selectOrDie("destroy");
            $select.prop("disabled", false);
            $select.parent('.sod_select').parent('.js-select').removeClass('is-active');
          } else {
            resetSelect($select);
          }
          
          $select.selectOrDie({
            size: 6,
            onChange: function() {        
              App.Behaviors.SelectBox($(this));
            }
          });
      });
            
      
      $addTrigger.on('click', function(e) {
        e.preventDefault();
        var $clone = $itemWrap.eq(0).clone(true);
        
        $clone.find('input').prop('checked', false);
        
        resetSelect($clone.find('select'));
        
        $clone.find('select').selectOrDie({
          size: 6,
          onChange: function() {        
            App.Behaviors.SelectBox($(this));
          }
        });
        
        var itemNum = Number($el.data('areaitem'));
        itemNum++;
        $el.data('areaitem', itemNum);
        
        var $trigger = $clone.find('.js-nagano-city-trigger'),
            $label = $trigger.next('label');
        $trigger.each(function(){
          var triggerId = $(this).attr('id'),
              labelId = $(this).next('label').attr('for');
              
          $(this).attr('id', triggerId + '-'+ itemNum);
          $(this).next('label').attr('for', labelId  + '-'+ itemNum);
        });
        
        $clone.prepend('<h4 class="formSection__subTtl">生産エリア' + itemNum +'<span class="js-remove-nagano-city"><span class="fas fa-times"></span></span></h4>');
        removeCity($clone.find('.js-remove-nagano-city'));
        $el.append($clone);
        
      });

      removeCity($removeTrigger);
      
    }

    /** ------------------------------
     *  Private
     ---------------------------------*/
    /**
     *  resetSelect
     *
     *  @function select初期化
     *  @private
     *  @param  {object} $el
     *  @return {void}
     */
     var resetSelect = function($el) {
        $el.selectOrDie("destroy");
        $el.prop("disabled", true);
        $el.val('');
        $el.parent('.sod_select').parent('.js-select').removeClass('is-active');
     }


    /**
     *  removeCity
     *
     *  @function 追加入力エリアの削除
     *  @private
     *  @param  {object} $el
     *  @return {void}
     */
     var removeCity = function($trigger) {
        $trigger.on('click', function(){
          var index = $('.js-nagano-city-items-wrap').index($(this).parents('.js-nagano-city-items-wrap'));
          
          $(this).parents('.js-nagano-city-items-wrap').remove();

          renameId(index);
          var itemNum = Number($el.data('areaitem'));
          itemNum--;
          $el.data('areaitem', itemNum);

        });
     }

    /**
     *  renameId
     *
     *  @function 追加入力エリアの削除
     *  @private
     *  @param  {object} $el
     *  @return {void}
     */

     var renameId = function(index) {
        var $target = $('.js-nagano-city-items-wrap').slice(index, $('.js-nagano-city-items-wrap').length);
        
        index++;
        $target.each(function(){
            $(this).find('.formSection__subTtl').html('生産エリア' + index + '<span class="js-remove-nagano-city"><span class="fas fa-times"></span></span>');
            removeCity($(this).find('.js-remove-nagano-city'));
            
            var $trigger = $(this).find('.js-nagano-city-trigger'),
                $label = $trigger.next('label');
            $trigger.each(function(){
              var triggerId = $(this).attr('id'),
                  labelId = $(this).next('label').attr('for');
                  
              $(this).attr('id', triggerId + '-'+ index);
              $(this).next('label').attr('for', labelId  + '-'+ index);
            });
            index++;
        });
     }
     

    /** ------------------------------
     *  Public
     ---------------------------------*/


    // ---------- init and export ----------
    init();
    return self;
};

}(this, this.document, this.App));

