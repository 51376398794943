/**
 *  SideNav.js
 *
 *  @project      nagano
 *  @since        2019/11/21
 *  @version      0.0.1
 *  @author       Ishibashi
 */

(function(win, doc, App){

"use strict";

/**
 * SP用 サイドメニュー 表示
 *
 * @public
 * @param  {string} selector
 * @return  {App.Behaviors.SideNav} self
 */
App.Behaviors.SideNavNaganoMall = function (selector) {
    var self = new App.Services.Observer(),
        $body = $('body'),
        $trigger = $(selector),
        $menu = $('.js-side-nav-contents'),
        $modalMenu = $('.js-modal-menu'),
        hh = $('.js-header').height() + $('.header-read').outerHeight(),
        scrollPos,
        $bg = $('.js-side-overlay'),
        $modalBg = $('.js-modal-menu-overlay'),
        $targetWrapSp = $('.js-pinpoint-serach-child-wrap-sp'),
        $spClose = $('.js-side-nav-trigger-mall'),
        show_flag = false;
        self._util = new App.Services.Util();

    /** ------------------------------
     *  Initialize
     ---------------------------------*/

    function init() {
        //右下メニュー用ボタンクリック時の処理
        $trigger.on('click', function(e){
            e.preventDefault();
            
            if (window.innerWidth > 767) {
                if($modalMenu.hasClass('is-active')) {
                    //メニュー非表示の処理
                    sideNavClose();
                    
                } else {
                    //メニュー表示の処理
                    if (show_flag) { return; }
                    show_flag = true;
                    sideNavOpen();
                }
            } else {
                if($menu.hasClass('is-active')) {
                    //メニュー非表示の処理
                    sideNavClose();
                    
                } else {
                    //メニュー表示の処理
                    if (show_flag) { return; }
                    show_flag = true;
                    sideNavOpen();
                }
            }
        });
        
        //背景クリックで閉じる処理
        $bg.on('click', function() {
          $trigger.click();
        });
        $modalBg.on('click', function() {
          $body.removeClass('is-fixed');
          $body.css({'top': 0});
          $(window).scrollTop(scrollPos);

          $modalMenu.removeClass('is-active');
          $modalMenu.fadeOut(500);
          show_flag = false;
        });
        
        $(window).on('resize', function(){
            if (window.innerWidth > 767) {
                if ($menu.hasClass('is-active')) {
                    sideNavClose()
                }
            }
            if (window.innerWidth < 768) {
                if ($modalMenu.hasClass('is-active')) {
                    $modalMenu.removeClass('is-active');
                    $modalMenu.fadeOut(500);
                }
            }
        });


        //
        $('.js-sub-category-trigger').find('.js-category-trigger').on('mouseenter', function(){
            if($(this).parents('.js-sub-category-trigger').hasClass('is-hover') || $modalMenu.hasClass('is-subcat')) {
                return;
            } else {
              $modalMenu.addClass('is-subcat');
              $(this).parents('.js-sub-category-trigger').addClass('is-hover');
              
            }
        })
        //メニュー
        $('.js-sub-category-bg').on('click', function(e){
            e.preventDefault();
            $('.js-sub-category-trigger').removeClass('is-hover');
            setTimeout(function(){
              $modalMenu.removeClass('is-subcat');
            }, 300);
        });
        
        //SPカテゴリー アコーディオン
        $('.js-side-category').each(function(){
            var $this = $(this);
            $this.find('.js-side-category-trigger').on('click',function(){
                if($(this).hasClass('is-active')) {
                    $(this).removeClass('is-active');
                    $this.find('.js-side-category-list').slideUp();
                } else {
                    $(this).addClass('is-active');
                    $this.find('.js-side-category-list').slideDown();
                }
            });
        });
        
    }

    /** ------------------------------
     *  Private
     ---------------------------------*/
    /**
     *  sideNavOpen
     *
     *  @function　サイドナビを表示にする処理
     *  @public
     *  @return {void}
     */
    var sideNavOpen = function () {
        if (window.innerWidth < 768) {
            $menu.addClass('is-active');
            $bg.fadeIn(200);
            $spClose.fadeIn(400);
            
            scrollPos = $(window).scrollTop();
            $body.css({'top': -(scrollPos) });
            $body.addClass('is-fixed');
            
            //ヘッダーが表示している場合はヘッダー分ずらし処理
            if (scrollPos < hh) {
              var postop = hh - scrollPos;
              $menu.css({'top': postop, 'height': 'calc(100% - '+ postop +'px)'}); 
              $bg.css({'top': postop, 'height': 'calc(100% - '+ postop +'px)'}); 
            } else {
              $menu.css({'top': 0, 'height': '100%'}); 
              $bg.css({'top': 0, 'height': '100%'}); 
            }
        } else {
            scrollPos = $(window).scrollTop();
            $body.css({'top': -(scrollPos) });
            $body.addClass('is-fixed');


            $modalMenu.addClass('is-active');
            $modalMenu.fadeIn(500).css({'display':'flex'});
        }
     }
    /**
     *  sideNavClose
     *
     *  @function　サイドナビを非表示にする処理
     *  @public
     *  @return {void}
     */
    var sideNavClose = function () {
        if (window.innerWidth < 768) {
            $menu.removeClass('is-active');
            $spClose.fadeOut(200);
            $bg.fadeOut(200, function(){
                if ($menu.hasClass('is-sub')) {
                    $menu.removeClass('is-sub');
                }
                setTimeout(function(){
                  if ($targetWrapSp.hasClass('is-active')) {
                      $targetWrapSp.removeClass('is-active');
                  }
                }, 200);
                setTimeout(function(){
                  $body.removeClass('is-fixed');
                  $body.css({'top': 0});
                  $(window).scrollTop(scrollPos);
                  $('.js-side-nav-contents-inner').scrollTop(0);
                  $menu.css({'top': 0, 'height': '100%'}); 
                  $bg.css({'top': 0, 'height': '100%'});
                  show_flag = false;
                }, 500);
            });
        } else {
            $body.removeClass('is-fixed');
            $body.css({'top': 0});
            $(window).scrollTop(scrollPos);

            $modalMenu.removeClass('is-active');
            $modalMenu.fadeOut(500);
            show_flag = false;
        }
     }
      
    /** ------------------------------
     *  Public
     ---------------------------------*/


    // ---------- init and export ----------
    init();
    return self;
};

}(this, this.document, this.App));

