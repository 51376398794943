/**
 *  Tag.js
 *
 *  @project      nagano
 *  @since        2019/11/13
 *  @version      0.0.1
 *  @author       Ishibashi
 */

(function(win, doc, App){

"use strict";

/**
 *  a要素内のタグクリック時の処理
 *
 * @public
 * @param {string} selector
 * @return {App.Behaviors.Tag}
 */
App.Behaviors.ModalRegistration = function () {
    var self = new App.Services.Observer(),
        $storeForm = $('#store-registration'),
        $storeSubmit = $('.js-store-registration-submit'),
        $siteForm = $('#site-registration'),
        $siteSubmit = $('.js-site-registration-submit'),
        is_apiCall = false,
        calling_api = false;
    self._util = new App.Services.Util();
    self._validate = new App.Behaviors.Validator();
    App.Behaviors.StoreValidator();
    self._fm = new App.Behaviors.FlashMessenger();
    /** ------------------------------
     *  Initialize
     ---------------------------------*/
    function init () {
        App.Behaviors.SetAddress($('#modal-store-registration').find('.js-set-address'));
        
        $('#shop-zipcode').on("change", function() {
          // 全角数字を半角に変換
          var jan = $(this).val().replace(/[０-９！-～]/g, function(s){
            return String.fromCharCode(s.charCodeAt(0)-0xFEE0);
          });
          $(this).val(jan);
        });

        $('.js-modal-store-registration-trigger, .js-modal-site-registration-trigger').on('click', function(){
            setTimeout(function(){
                $('#modal-store-registration, #modal-site-registration').find('.js-select select').selectOrDie({
                    size: 6,
                    onChange: function() {    
                        App.Behaviors.SelectBox($(this));
                    }
                });
            }, 300);
        });
        var type = 1,
            $storePhoneNumber = $('.js-store-phone-number'),
            $deliveryField = $('.js-delivery-field'),
            $storeField = $('.js-store-field'),
            $areaName = $('.js-area-name');
            
            //初期表示は 飲食店
            $deliveryField.hide();
            $deliveryField.find('input').prop('disabled', true);
            $deliveryField.find('select').prop('disabled', true);
            
        
        //掲載タイプ別の表示
        $('.js-posting-type').on('change', function(){
            var val = $(this).val();

            if (val != 1) {
              
                $deliveryField.fadeIn();
                $deliveryField.find('input').prop('disabled', false);
                $deliveryField.find('select').prop('disabled', false);
                $storeField.hide();
                $storeField.find('input').prop('disabled', true);
                $storeField.find('select').prop('disabled', true);
                $areaName.text('デリバリー・テイクアウト対象');
                
            }  else {
                $storeField.fadeIn();
                $storeField.find('input').prop('disabled', false);
                $storeField.find('select').prop('disabled', false);
                $deliveryField.hide();
                $deliveryField.find('input').prop('disabled', true);
                $deliveryField.find('select').prop('disabled', true);
                $areaName.text('店舗所在');
            }
        });
        
        //ホームページがないい場合の表示
        $('.js-is-no-hp').on('change' , function(){
            if ($(this).prop('checked') == true) {
                $storePhoneNumber.fadeIn();
            } else {
                $storePhoneNumber.fadeOut();
            }
        });
        
        
        //モーダル問い合わせバリデーション追加
        $storeSubmit.on('click', function(e) {
            e.preventDefault();

            // ２重送信防止
            if(calling_api) {
                self._fm.flashMessageDanger('ページを更新してください');
                return false;
            }

            formSendAction($storeForm);
        });
    }

    /** ------------------------------
     *  Private
     ---------------------------------*/
    /**
     *
     * @function お問い合わせフォーム 送信
     * @private
     * @param  {obj} $form
     * @return {void}
     */
     function formSendAction($targetForm){
        //バリデーション
        var that = this,
            valid = true;

        $targetForm.find('input,select,textarea').trigger('validate');

        // バリデートエラーが発生していないかチェック
        setTimeout(function() {
            if ($('.error-message:visible').length > 0 || !valid) {
                self._fm.flashMessageDanger('不十分な入力項目があります');
                return false;
            } else {
                sendStoreRegistration();
            }
        }, 0);
     }

    /**
     *  getCheckVal
     *
     *  @function チェックボックスの値を取得
     *  @private
     *  @return {string} val
     */

    function getCheckVal ($el) {
      var ids = [],
          cnt = 0;
      $el.find('input').each(function(){
          if(!$(this).hasClass('js-no-area-checkbox')) {
              if ($(this).prop('checked')) {
                cnt++;
                ids.push($(this).val());
              }
          }
          
      });
      if (cnt == 0) {
        ids = '';
      }
      return {'_ids': ids};
    }
    
    /**
     *  constructParam
     *
     *  @function リクエストパラメーターを構築
     *  @private
     *  @return {object} p
     */
    function constructParam () {
        var p = {};
            p['name']                                    = $('#name').val();
            p['email']                                   = $('#email').val(); 
            p['tel']                                     = $('#tel').val();
            p['master_mall_post_site_order_category_id'] = $('#master-mall-post-site-order-category-id').val();
            p['master_nagano_wide_area_id']              = getCheckVal($('.store-location-area'));//エリア
            
            if ($('#master-mall-post-site-order-category-id').val() == 1) {
                p['service_shop_name'] = $('#i-service-shop-name').val();
                p['shop_zipcode']      = $('#shop-zipcode').val();
                p['shop_address']      = $('#shop-address').val();
                p['url']               = $('#posting-url').val();
                p['is_no_hp']          = $('#is-no-hp-1').prop('chacked') ? 1:0;
                p['shop_tel']          = $('#shop-tel').val();
                p['store_description'] = $('#store-description').val();

            } else {
                p['service_shop_name']  = $('#d-service-shop-name').val();
                p['url']                = $('#url').val();
                p['supplementary_info'] = $('#supplementary_info').val();
            }
            
            if($('#grecaptchaWidgetId').length > 0) {
                p['token'] = $('#grecaptchaWidgetId').val();
            }
        
        return p;
    }

    /**
     *  sendStoreRegistration
     *
     *  @function 掲載希望者登録データ登録
     *  @private
     *  @return {void}
     */
    function sendStoreRegistration() {
        var type = 'POST',
            url = '/apiInternal/sendMallHopeToPublish',
            params = constructParam(),
            datatype = 'json';
        self._util
            .ajax(type, url, params, datatype)
            .done(function(res) {
                var valid = (res.status === 0) ? true : false;
                if(valid) {
                  self._fm.flashMessageSuccess('送信完了致しました。担当者よりご連絡致しますので今しばらくお待ちください。');
                  $('#modal-store-registration').fadeOut(function(){
                      $('#modal-store-registration').find('input,select').each(function(){
                          $(this).val('');
                      });
                      $('#modal-store-registration').find('input[type=checkbox]').each(function(){
                          $(this).prop('checked',false);
                      });
                  });
                  calling_api = false;

                } else {
                    // error message
                    if (res.status === -2) {
                      var errorMessage = errorMessageFromServer(res.data.errors);
                      self._fm.flashMessageDanger(errorMessage);
                    } else {
                      self._fm.flashMessageDanger(res.message);
                    }
                    return;
                }
            })
            .fail(function(data) {
              // error message
                self._fm.flashMessageDanger('エラーが発生しました、時間を置いてお試し下さい、申し訳ありません');
            })
            .always(function() {
                calling_api = false;
            });
    }


    /** ------------------------------
     *  Public
     ---------------------------------*/

    // ---------- init and export ----------
    init();
    return self;
};

}(this, this.document, this.App));

