/**
 *  PinPointSearch.js
 *
 *  @project      nagano
 *  @since        2019/11/21
 *  @version      0.0.1
 *  @author       Ishibashi
 */

(function(win, doc, App){

"use strict";

/**
 * サイドメニュー ピンポイント検索
 *
 * @public
 * @param  {string} selector
 * @return  {App.Behaviors.PinPointSearch} self
 */
App.Behaviors.PinPointSearch = function (selector) {
    var self = new App.Services.Observer(),
        $side = $('.js-side-nav-contents'),
        $trigger = $(selector).find('.js-pinpoint-serach-trigger'),
        $targetWrap = $('.js-pinpoint-serach-child-wrap'),
        $targetWrapSp = $('.js-pinpoint-serach-child-wrap-sp'),
        $target = $targetWrap.find('.side-menu-child'),
        $targetSp = $targetWrapSp.find('.side-menu-child'),
         hh = $('.js-header').height(),
         scrollPos;
        
        self._util = new App.Services.Util();
        self._fm = new App.Behaviors.FlashMessenger();

    /** ------------------------------
     *  Initialize
     ---------------------------------*/

    function init() {      
        var active,
            showTimer;
        
        if (window.innerWidth > 767) {
            $trigger.on('mouseenter', function(){
              clearTimeout(showTimer);
              var pos = $(this).offset().top;                  
                  pos = pos + $(this).outerHeight()/2;
              var index = $trigger.index($(this));
              $trigger.removeClass('is-active');
              $target.hide();
              
              $(this).addClass('is-active');
              $target.eq(index).show();
              $target.css({'top': pos});
            });
            
            $trigger.on('mouseleave', function(){
              showTimer = setTimeout(function(){
                $trigger.removeClass('is-active');
                $target.hide();
              }, 200);
            });
            
            $target.on('mouseenter', function(){
              clearTimeout(showTimer);
            });
            $target.on('mouseleave', function(){
              showTimer = setTimeout(function(){
                $trigger.removeClass('is-active');
                $target.hide();
              }, 200);
            });
            
            $trigger.on('click', function(e){
              e.preventDefault();
            });
        } else {
          $trigger.on('click', function(e){
            e.preventDefault();
            $targetSp.hide();
            var index = $trigger.index($(this));
            $targetSp.eq(index).show();
            $targetWrapSp.addClass('is-active');
            $side.addClass('is-sub');
          });
          
          $('.js-pinpoint-serach-close').on('click', function(e){
            e.preventDefault();
            $targetSp.hide();
            $targetWrapSp.removeClass('is-active');
            $targetWrapSp.removeAttr('style'); 
            $side.removeClass('is-sub');
          });
        }
    }

    /** ------------------------------
     *  Private
     ---------------------------------*/


      
    /** ------------------------------
     *  Public
     ---------------------------------*/


    // ---------- init and export ----------
    init();
    return self;
};

}(this, this.document, this.App));

