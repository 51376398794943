/**
 *  CategorySearch.js
 *
 *  @project      nagano
 *  @since        2020/02/24
 *  @version      0.0.1
 *  @author       Ishibashi
 */

(function(win, doc, App){

"use strict";

/**
 * サイドメニュー カテゴリ検索
 *
 * @public
 * @param  {string} selector
 * @return  {App.Behaviors.CategorySearch} self
 */
App.Behaviors.CategorySearch = function (selector) {
    var self = new App.Services.Observer(),
        $side = $('.js-side-nav-contents'),
        $trigger = $(selector).find('.js-category-serach-trigger'),
        $targetWrap = $('.js-category-serach-child-wrap'),
        $targetWrapSp = $('.js-category-serach-child-wrap-sp'),
        $target = $targetWrap.find('.side-menu-child'),
        $targetSp = $targetWrapSp.find('.side-menu-child'),
        $targetSub = $targetWrap.find('.side-menu-sub-child,.side-menu-sub-sub-child'),

        $triggerSubSp = $targetWrapSp.find('.js-side-menu-sub-trigger'),
        $triggerSubSubSp = $targetWrapSp.find('.js-side-menu-sub-sub-trigger'),

        $targetSubSp = $targetWrapSp.find('.js-side-menu-sub-child'),
        $targetSubSubSp = $targetWrapSp.find('.js-side-menu-sub-sub-child'),

         hh = $('.js-header').height(),
         scrollPos;
        
        self._util = new App.Services.Util();
        self._fm = new App.Behaviors.FlashMessenger();

    /** ------------------------------
     *  Initialize
     ---------------------------------*/

    function init() {      
        var active,
            showTimer;
                    
        if (window.innerWidth > 767) {
            $trigger.on('mouseenter', function(){

              clearTimeout(showTimer);
              var pos = $(this).offset().top;
                  pos = pos + $(this).outerHeight()/2;
              var index = $(this).data('cat-id');

              $trigger.removeClass('is-active');
              $target.hide();
              
              if ($('html').hasClass('is_tb')) {
                return;
              }
              
              $(this).addClass('is-active');
              $target.eq(index - 1).show();
              $target.css({'top': pos});
            });
            
            $trigger.on('mouseleave', function(){
              showTimer = setTimeout(function(){
                $trigger.removeClass('is-active');
                $target.hide();
              }, 200);
            });
            
            $target.on('mouseenter', function(){
              clearTimeout(showTimer);
            });
            $target.on('mouseleave', function(){
              showTimer = setTimeout(function(){
                $trigger.removeClass('is-active');
                $target.hide();
              }, 200);
            });

            $targetSub.on('mouseenter', function(){
              clearTimeout(showTimer);
            });
            $targetSub.on('mouseleave', function(){
              showTimer = setTimeout(function(){
              }, 200);
            });
        } else {
          $trigger.on('click', function(e){
            
            //if ($(this).hasClass('js-category-serach-trigger')) { return; }
            
            e.preventDefault();
            $targetSp.hide();
            var index = $(this).data('cat-id');
            $targetSp.eq(index - 1).show();
            $targetWrapSp.addClass('is-active');
            $side.addClass('is-sub');
          });
          
          $('.js-category-serach-close').on('click', function(e){
            e.preventDefault();
            $targetSp.hide();
            $targetWrapSp.removeClass('is-active');
            $targetWrapSp.removeAttr('style'); 
            $side.removeClass('is-sub');
            $targetSubSp.removeClass('is-active');
            $targetSubSubSp.removeClass('is-active');
            
          });
          
          $triggerSubSp.on('click', function(e){
            e.preventDefault();
            if(!$(this).next('.js-side-menu-sub-child').length > 0){
              var url = $(this).attr('href');
              location.href = url;
            } else {
              $(this).next('.js-side-menu-sub-child').addClass('is-active');
            }
          });
          $triggerSubSubSp.on('click', function(e){
            e.preventDefault();
            if(!$(this).next('.js-side-menu-sub-sub-child').length > 0){
              var url = $(this).attr('href');
              location.href = url;
            } else {
              $(this).next('.js-side-menu-sub-sub-child').addClass('is-active');
            }
          });          
        }
    }

    /** ------------------------------
     *  Private
     ---------------------------------*/


      
    /** ------------------------------
     *  Public
     ---------------------------------*/


    // ---------- init and export ----------
    init();
    return self;
};

}(this, this.document, this.App));

