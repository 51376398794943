/**
 *  SelectBox.js
 *
 *  @project      shareshima
 *  @since        2019/10/08
 *  @version      0.0.1
 *  @author       Ishibashi
 */

(function(win, doc, App){

"use strict";

/**
 * ログイン前 ご利用の流れ メニュー表示
 *
 * @public
 * @param  {string} selector
 * @return  {App.Behaviors.SelectBox} self
 */
App.Behaviors.HeaderMenu = function (selector) {
    var self = new App.Services.Observer(),
        $el = $(selector),
        $trigger = $el.find('.js-header-menu-flow-trigger'),
        $contents = $el.find('.js-header-menu-flow-contents'),
        timer;
        
    self._util = new App.Services.Util();
      
    /** ------------------------------
     *  Initialize
     ---------------------------------*/

    function init() {
      
      $trigger.on('click', function(e) {
          e.preventDefault();
      });
      
      $('.js-header-menu-flow-trigger, .js-header-menu-flow-contents').on('mouseenter touchstart', function() {
          clearTimeout(timer);
          $contents.fadeIn(300);
      });
      
      $('.js-header-menu-flow-trigger, .js-header-menu-flow-contents').on('mouseleave touchmove', function() {
          timer = setTimeout(function() {
              $contents.fadeOut(100);
          }, 200);
      });
      if (self._util.getuserAgent().SP_TB) {
          $(window).on('scroll', function(){
              if ($contents.css('display') == 'block') {
                timer = setTimeout(function() {
                    $contents.fadeOut(100);
                }, 200);
              }
          });
      }
      
    }

    /** ------------------------------
     *  Private
     ---------------------------------*/


    /** ------------------------------
     *  Public
     ---------------------------------*/


    // ---------- init and export ----------
    init();
    return self;
};

}(this, this.document, this.App));

