/**
 *  AreaSearch.js
 *
 *  @project      nagano
 *  @since        2019/11/26
 *  @version      0.0.1
 *  @author       Ishibashi
 */

(function(win, doc, App){

"use strict";

/**
 * エリア検索
 *
 * @public
 * @param  {string} selector
 * @return  {App.Behaviors.AreaSearch} self
 */
App.Behaviors.AreaSearch = function (selector) {
    var self = new App.Services.Observer(),
        $el = $(selector),
        $map = $el.find('.js-area-search-map'),
        $activeMap = $el.find('.js-area-search-active-map'),
        showTimer,
        $areas = $el.find('.js-area-search-areas'),
        $cities = $el.find('.js-area-search-cities'),
        $submitBtn = $el.find('.js-area-search-submit'),
        dataL = AreaApp.masterNaganoAreas,
        dataM = AreaApp.masterNaganoCities;
        self._util = new App.Services.Util();

    /** ------------------------------
     *  Initialize
     ---------------------------------*/

    function init() {   
        //map   
        $('img[usemap]').rwdImageMaps();
        
        $map.on('mouseenter', function(){
            var index = $map.index($(this));
            $activeMap.removeClass('is-hover');
            $activeMap.eq(index).addClass('is-hover');
        });
        $map.on('mouseleave', function(){
            $activeMap.removeClass('is-hover');
        });
        
        $map.on('click', function(e){
            e.preventDefault();
            var index = $map.index($(this));
            $activeMap.removeClass('is-active');
            $activeMap.eq(index).addClass('is-active');
            $areas.val(index + 1);
            $areas.change();
        });


        //セレクト
        $cities.prop("disabled", true);
        
        //大分類の生成
        $.each(dataL, function(i){
          if (i == 9999) { return; }
          $areas.append($('<option>').html(dataL[i]).val(i));
        });
  
        //selectの表示制御
        $areas.selectOrDie({
          size: 6,
          onChange: function() {
            $activeMap.removeClass('is-active');
            $activeMap.eq($(this).val() - 1).addClass('is-active');

            buildSelect(dataM, $cities, $(this).val(), 'M');
            if ($(this).val()) {
              $cities.parent('.sod_select').parent('.js-select').addClass('is-active');
              $cities.selectOrDie("destroy");
              $cities.prop("disabled", false);
  
              changeCategorySub();
  
            }          
            App.Behaviors.SelectBox($(this));
          }
        });
  
        changeCategorySub();

        $submitBtn.on('click', function(e){
            e.preventDefault();
            if ($areas.val() == null) return false;
            var link;
            if ($cities.val() != null) {
              link = '/foods?city_id=' + $cities.val();
            } else {
              link = '/foods?area_id=' + $areas.val();
            }
            location.href = link;
        });
    }

    /** ------------------------------
     *  Private
     ---------------------------------*/
    /**
     *  changeCategorySub
     *
     *  @function サブカテゴリーの処理
     *  @private
     *  @return {void}
     */
     
     var changeCategorySub = function() {
        $cities.selectOrDie({
          size: 6,
          onChange: function() { App.Behaviors.SelectBox($(this)); }
        });
     }
     
    /**
     *  buildSelect
     *
     *  @function 選択された値でselect生成
     *  @private
     *  @param  {obj} data
     *  @param  {dom} $select
     *  @param  {string} val
     *  @param  {string} cat
     *  @return {void}
     */
     var buildSelect = function(data, $select, val, cat) {
       var plh = '市町村を選択';
        $select.find('option').remove();
        $select.append($('<option>').html(plh).val(''));
        $select.change();

        $.each(data, function(i){    
          if (data[i].cat_id == val) {
             $select.append($('<option>').html(data[i].text).val(i));
          }
        });      
     }

    /**
     *  resetSelect
     *
     *  @function select初期化
     *  @private
     *  @param  {arr} data
     *  @return {void}
     */
     var resetSelect = function(data) {
        $.each(data, function(i){
            data[i].selectOrDie("destroy");
            data[i].prop("disabled", true);
            data[i].val('');
            data[i].parent('.sod_select').parent('.js-select').removeClass('is-active');
        });      
     }
     
    /**
     *  getUrlVars
     *
     *  @function GETパラメータを配列にして返す
     *  @private
     *  @return {obj}
     */
      var getUrlVars = function(){
        var vars = {}; 
        var param = location.search.substring(1).split('&');
        for(var i = 0; i < param.length; i++) {
            var keySearch = param[i].search(/=/);
            var key = '';
            if(keySearch != -1) key = param[i].slice(0, keySearch);
            var val = param[i].slice(param[i].indexOf('=', 0) + 1);
            if(key != '') vars[key] = decodeURI(val);
        } 
        return vars; 
      }


      
    /** ------------------------------
     *  Public
     ---------------------------------*/


    // ---------- init and export ----------
    init();
    return self;
};

}(this, this.document, this.App));

